import React, { useState, useEffect } from 'react'
import axios from 'axios'

import API from './Api'
import { useNavigate } from 'react-router-dom';


export default function Expertise() {
  const [isFetch,setFetch] = useState(true);
  const router = useNavigate()

  const [fullname, setfullname] = useState({
    head: '',
    que1: '',
    boxhead1: '',
    boxpara1: '',
    boxhead2: '',
    boxpara2: '',
    boxhead3: '',
    boxpara3: '',
    boxhead4: '',
    boxpara4: '',
    boxhead5: '',
    boxpara5: '',
    boxhead6: '',
    boxpara6: '',
    que2: '',
    right1: '',
    right2: '',
    right3: '',
    
    head1: '',
    que11: '',
    boxhead11: '',
    boxpara11: '',
    boxhead21: '',
    boxpara21: '',
    boxhead31: '',
    boxpara31: '',
    boxhead41: '',
    boxpara41: '',
    boxhead51: '',
    boxpara51: '',
    boxhead61: '',
    boxpara61: '',
    que21: '',
    right11: '',
    right21: '',
    right31: ''
  });

  useEffect(() => {
    async function getRepo() {
      const res = await axios.get(`${API.BASE_URL}/home/expertises`)
        .then((res) => {
          const datas = res.data;
          console.log(datas.data[0], "this is dataas")
          let obj = {
            ...datas.data[0].en,
            head1: datas.data[0].sp.head,
            que11: datas.data[0].sp.que1,
            boxhead11: datas.data[0].sp.boxhead1,
            boxpara11: datas.data[0].sp.boxpara1,
            boxhead21: datas.data[0].sp.boxhead2,
            boxpara21: datas.data[0].sp.boxpara2,
            boxhead31: datas.data[0].sp.boxhead3,
            boxpara31: datas.data[0].sp.boxpara3,
            boxhead41: datas.data[0].sp.boxhead4,
            boxpara41: datas.data[0].sp.boxpara4,
            boxhead51: datas.data[0].sp.boxhead5,
            boxpara51: datas.data[0].sp.boxpara5,
            boxhead61: datas.data[0].sp.boxhead6,
            boxpara61: datas.data[0].sp.boxpara6,
            que21: datas.data[0].sp.que2,
            right11: datas.data[0].sp.right1,
            right21: datas.data[0].sp.right2,
            right31: datas.data[0].sp.right3
          }
          setfullname(obj)
          setFetch(false)
        });
    }
      getRepo();
  },[])


  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    let obj = {
        _id: "62cea1c0369bfe31ce97d249",
      name : "expertise",
      en : {
        head: fullname.head ,
        que1: fullname.que1 ,
        boxhead1: fullname.boxhead1 ,
        boxpara1: fullname.boxpara1 ,
        boxhead2: fullname.boxhead2 ,
        boxpara2: fullname.boxpara2 ,
        boxhead3: fullname.boxhead3 ,
        boxpara3: fullname.boxpara3 ,
        boxhead4: fullname.boxhead4 ,
        boxpara4: fullname.boxpara4 ,
        boxhead5: fullname.boxhead5 ,
        boxpara5: fullname.boxpara5 ,
        boxhead6: fullname.boxhead6 ,
        boxpara6: fullname.boxpara6 ,
        que2: fullname.que2 ,
        right1: fullname.right1 ,
        right2: fullname.right2 ,
        right3: fullname.right3 
      },
      sp : {
        head: fullname.head1 ,
        que1: fullname.que11 ,
        boxhead1: fullname.boxhead11 ,
        boxpara1: fullname.boxpara11 ,
        boxhead2: fullname.boxhead21 ,
        boxpara2: fullname.boxpara21 ,
        boxhead3: fullname.boxhead31 ,
        boxpara3: fullname.boxpara31 ,
        boxhead4: fullname.boxhead41 ,
        boxpara4: fullname.boxpara41 ,
        boxhead5: fullname.boxhead51 ,
        boxpara5: fullname.boxpara51 ,
        boxhead6: fullname.boxhead61 ,
        boxpara6: fullname.boxpara61 ,
        que2: fullname.que21 ,
        right1: fullname.right11 ,
        right2: fullname.right21 ,
        right3: fullname.right31 
      },
      
    }
    async function sendData() {
      const res = await axios.put(`${API.BASE_URL}/home/expertise`, obj,{
        headers: {
          'x-token': localStorage.getItem('token')
        }
      })
        .then((res) => {
          if (res.data.status) {
            alert("Data Updated")
          } else {
            if (res.data.code === 401) {
              localStorage.clear();
              router('/login')
            }
          }
        }).catch((err)=>{
          console.log(err.message)
        })
    }
    sendData();
}


  return (
    <>
      <main id="main" className="main">

        <section className="section">
          <div className="row">
            <div className="col-lg-6">
              <div className="row mb-3">
                <h3>English</h3>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <h3>Spanish</h3>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head} name='head' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head1} name='head1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">que1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.que1} name='que1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.que11} name='que11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1} name='boxhead1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead11} name='boxhead11' />
                </div>
              </div>
            </div>

            
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxpara1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara1} name='boxpara1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara11} name='boxpara11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2} name='boxhead2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead21} name='boxhead21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxpara2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara2} name='boxpara2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara21} name='boxpara21' />
                </div>
              </div>
            </div>

            
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3} name='boxhead3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead31} name='boxhead31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxpara3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara3} name='boxpara3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara31} name='boxpara31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4} name='boxhead4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead41} name='boxhead41' />
                </div>
              </div>
            </div>

            

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxpara4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara4} name='boxpara4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara41} name='boxpara41' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5} name='boxhead5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead51} name='boxhead51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxpara5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara5} name='boxpara5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara51} name='boxpara51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6} name='boxhead6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead61} name='boxhead61' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxpara6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara6} name='boxpara6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxpara61} name='boxpara61' />
                </div>
              </div>
            </div>
           

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">que2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.que2} name='que2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.que21} name='que21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">right1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right1} name='right1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right11} name='right11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">right2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right2} name='right2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right21} name='right21' />
                </div>
              </div>
            </div>

            
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">right3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right3} name='right3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right31} name='right31' />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button>
          </div>
        </section>

      </main>
    </>

  )

  }