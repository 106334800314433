import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from './Home'
import Fintech from './Fintech'
import Blockchain from './Blockchain'
import Ai from './Ai'
import Internet from './Internet'
import Game from './Game'
import Robotic from './Robotic'
import Expertise from './Expertise'
import Prcoess from './Process'
import About from './About'
import Contact from './Contact'
import Error_page from './Error_page'
import ProtectedRoute from './Auth';
import Login from "./Login";
import Recover from './Recover';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import Testimonials from './Testimonials'
import AddBlogs from './AddBlogs'
import Blogs from './Blogs'
import EditBlogs from './EditBlog'
import Faqs from './Faqs'
import AddFaq from './AddFaq'
import EditFaq from './EditFaq'

export default function Linking() {
    return (
        <>
            <Routes>
                <Route exact path='/' element={<ProtectedRoute name='/Home' component={Home}/>} />
                <Route exact path='/home' element={<ProtectedRoute name='/Home' component={Home}/>} />
                <Route exact path='/fintech' element={<ProtectedRoute name='/Fintech' component={Fintech}/>} />
                <Route exact path='/blockchain' element={<ProtectedRoute name='/Blockchain' component={Blockchain}/>} />
                <Route exact path='/artificial-intelligence' element={<ProtectedRoute name='/Ai' component={Ai}/>} />
                <Route exact path='/iot' element={<ProtectedRoute name='/Internet' component={Internet}/>} />
                <Route exact path='/ARV' element={<ProtectedRoute name='/Game' component={Game}/>} />
                <Route exact path='/robotics' element={<ProtectedRoute name='/Robotic' component={Robotic}/>} />
                <Route exact path='/expertise' element={<ProtectedRoute name='/Expertise' component={Expertise}/>} />
                <Route exact path='/process' element={<ProtectedRoute name='/Prcoess' component={Prcoess}/>} />
                <Route exact path='/about' element={<ProtectedRoute name='/About' component={About}/>} />
                <Route exact path='/contact' element={<ProtectedRoute name='/Contact' component={Contact}/>} />
                <Route exact path='/edit-profile' element={<ProtectedRoute name='/EditProfile' component={EditProfile}/>} />
                <Route exact path='/change-password' element={<ProtectedRoute name='/ChangePassword' component={ChangePassword}/>} />
                <Route exact path='/testimonials' element={<ProtectedRoute name='/Testimonials' component={Testimonials}/>} />
                <Route exact path='/faqs' element={<ProtectedRoute name='/Faqs' component={Faqs}/>} />
                <Route exact path='/blogs/add' element={<ProtectedRoute name='/Blogs/add' component={AddBlogs}/>} />
                <Route exact path='/faqs/add' element={<ProtectedRoute name='/Faqs/add' component={AddFaq}/>} />
                <Route exact path='/faqs/:id' element={<ProtectedRoute name='/Faqs/edit' component={EditFaq}/>} />
                <Route exact path='/blogs' element={<ProtectedRoute name='/Blogs' component={Blogs}/>} />
                <Route exact path='/blogs/:id' element={<ProtectedRoute name='/Blogs' component={EditBlogs}/>} />
                <Route exact path='*' element={<Error_page/>} />
        <Route path="/login" element={<ProtectedRoute name='/Login' component={Login}/>}/>
        <Route path="/forgot-password" element={<ProtectedRoute name='/Recover' component={Recover}/>}/>
            </Routes>
        </>
    )
}
