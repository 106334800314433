import logo from './logo.svg';
import './App.css';
import { useNavigate,Routes } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import  Home  from "./Home";
import  Fintech  from "./Fintech";
import Linking from './Linking';
import {useState} from 'react';
import Login from './Login';

function App() {
  const router = useNavigate();
  const token = localStorage.getItem("token");
  let isAuthenticated = (token != null ? token.length >= 148 : false);
  return (
    <>
      {isAuthenticated ? <Header /> : null}
      {isAuthenticated ? <Sidebar /> : null}
      <Linking/>
    </>
  );
}

export default App;
