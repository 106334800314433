import React, { useState } from "react";
import "./index.css";
import { Router, useNavigate,Link } from "react-router-dom";
import axios from 'axios';
import API from './Api'

export default function Login() {
  const [logindetails, setLoginDetails] = useState({ email: "", password: "" });
  const [msg, setMessage] = useState("");
  const router = useNavigate();
  const inputevent = (event) => {
    const { value, name } = event.target;
    setLoginDetails((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const submitForm = (event) => {
    event.preventDefault();
    let obj = {
      ...logindetails,
    };
    axios.post(`${API.BASE_URL}/admin/login`,obj).then((res)=>{
      console.log(res," this svgbgfbgbsiubi")
      if(res.data.status){
        localStorage.setItem('email',res.data.user.email);
        localStorage.setItem('username',res.data.user.username);
        localStorage.setItem('token',res.data.user.accessToken);
        localStorage.setItem('name',res.data.user.name);
        localStorage.setItem('profilePic',res.data.user.profilePic);
        router('/');
        window.alert(res.data.message);
      }else{
        window.alert(res.data.message)
      }
    }).catch((err)=>{
      console.log(err,"this is was error ++++")
      window.alert(err.message)
    })
  };

  return (
    <>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div className="d-flex justify-content-center py-4">
                  <a
                    href="index.html"
                    className="logo d-flex align-items-center w-auto"
                  >
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">
                      Algo Coding Experts
                    </span>
                  </a>
                </div>

                <div className="card mb-3">
                  <div className="card-body">
                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">
                        Login to Your Account
                      </h5>
                      <p className="text-center small">
                        Enter your credentials to login
                      </p>
                    </div>

                    <form className="row g-3 needs-validation" novalidate>
                      <div className="col-12">
                        <label for="yourUsername" className="form-label">
                          Email
                        </label>
                        <div className="input-group has-validation">
                          <input
                            type="email"
                            name="email"
                            onChange={inputevent}
                            className="form-control"
                            id="yourUsername"
                            required
                          />
                          <div className="invalid-feedback">
                            Please enter your username.
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <label for="yourPassword" className="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          onChange={inputevent}
                          className="form-control"
                          id="yourPassword"
                          required
                        />
                        <div className="invalid-feedback">
                          Please enter your password!
                        </div>
                      </div>

                      {/* <div className="col-12">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="remember"
                            value="true"
                            id="rememberMe"
                          />
                          <label className="form-check-label" for="rememberMe">
                            Remember me
                          </label>
                        </div>
                      </div> */}
                      <div className="col-12">
                        <button className="btn btn-primary w-100" type="submit" onClick={submitForm}>
                          Login
                        </button>
                      </div>
                      <div className="col-12">
                        <p className="small mb-0">
                          Forgot your Password?{" "}
                          <Link to="/forgot-password">Recover Password</Link>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
