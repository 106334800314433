import React, { useEffect, useState } from "react";
import { NavLink,Link, useNavigate } from "react-router-dom";
import API from './Api'


export default function Header() {
  const [toggle, setToggle] = useState("toggle-sidebar");
  const [isFetch, setFetch] = useState(false);
  const [fullname, setfullname] = useState({
    name: "",
    email: "",
    username: "",
    profilePic: "",
  });

  let router = useNavigate();

  useEffect(()=>{
    if (!isFetch) {
      setfullname({
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        username: localStorage.getItem("username"),
        profilePic: localStorage.getItem("profilePic")
      });
    }
    
    setFetch(true);
  })

  const logout = (event) => {
    localStorage.clear();
    router('/login')
  }

  const openModal = (event) => {
    document.body.classList.toggle(toggle);
  };
  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <NavLink to="/home" className="logo d-flex align-items-center">
            <img src="assets/img/logo.svg" alt="" />
          </NavLink>
          <i className="bi bi-list toggle-sidebar-btn" onClick={openModal}></i>
        </div>
        <nav class="header-nav ms-auto">
          <ul class="d-flex align-items-center">
            <li class="nav-item dropdown pe-3">
              <Link
                class="nav-link nav-profile d-flex align-items-center pe-0"
                to="/"
                data-bs-toggle="dropdown"
              >
                <img
                  src={`${API.BASE_URL}/uploads/${fullname.profilePic}`}
                  alt="Profile" height={40} width={40}
                  class="rounded-circle"
                />
                <span class="d-none d-md-block dropdown-toggle ps-2">
                {fullname.name}
                </span>
              </Link>

              <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li class="dropdown-header">
                  <h6>{fullname.name}</h6>
                  <span>Admin</span>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <Link
                    class="dropdown-item d-flex align-items-center"
                    to="/edit-profile"
                  >
                    <i class="bi bi-person"></i>
                    <span>Edit Profile</span>
                  </Link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <Link
                    class="dropdown-item d-flex align-items-center"
                    to="/change-password"
                  >
                    <i class="bi bi-lock"></i>
                    <span>Change Password</span>
                  </Link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>

                <li>
                  <a class="dropdown-item d-flex align-items-center" href="#">
                    <i class="bi bi-box-arrow-right"></i>
                    <span onClick={logout}>Log Out</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}
