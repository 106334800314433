import React, { useState } from 'react'
import axios from 'axios';
import API from './Api'
import { useNavigate } from 'react-router-dom';
import Popup from './Popup';

const AddFaq = () => {
    const [type, setType] = useState('fintech')
    const [formVals, setForm] = useState({
        en: {
            heading: "",
            content: ""
        },
        sp: {
            heading: "",
            content: ""
        }
    })
    const router = useNavigate()
    const submitForm = async () => {
        await axios.post(`${API.BASE_URL}/faq/add`, {
            titleen: formVals?.en?.heading,
            titlesp: formVals?.sp?.heading,
            descriptionen: formVals?.en?.content,
            descriptionsp: formVals?.sp?.content,
            for: type
        }, {
            headers: {
                'x-token': localStorage.getItem('token')
            }
        }).then((res) => {
            if (res.data.status) {
                alert("Faq added successfully");
                setForm({
                    en: {
                        heading: "",
                        content: ""
                    },
                    sp: {
                        heading: "",
                        content: ""
                    }
                })
                setType('fintech')
                router(-1)
            } else {
                if (res.data.code === 401) {
                    localStorage.clear();
                    router('/login')
                }
            }
        }).catch((err) => {
            console.log(err.message);
        });
    }
    const [modalnum, setNumber] = useState({
        number: 1,
        state: false
    })
    const updateType = (e) => {
        setType(e.target.value)
    }
    return (
        <main id="main" className="main">
            <section className="section">
                <div className="row">
                    <div className='col-md-12'>
                        <select onChange={updateType} className="form-control mb-3">
                            <option value={'fintech'}>Fintech</option>
                            <option value={'blockchain'}>Blockchain</option>
                            <option value={'aiml'}>AI/ML</option>
                            <option value={'iot'}>IOT</option>
                            <option value={'arvr'}>AR/VR</option>
                            <option value={'robotics'}>Robotics</option>
                        </select>
                    </div>
                    <div className='col-md-6'>
                        <div className="from-group mb-3">
                            <label htmlFor="name_en">English Question</label>
                            <div className="input-group mb-3">
                                <input type="text" value={formVals?.en?.heading} className="form-control" id="basic-url" aria-describedby="basic-addon3" name='heading' onChange={(e) => {
                                    setForm({
                                        ...formVals,
                                        en: {
                                            ...formVals?.en,
                                            heading: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="from-group mb-3">
                            <label htmlFor="name_en">Spanish Question</label>
                            <div className="input-group mb-3">
                                <input type="text" value={formVals?.sp?.heading} className="form-control" id="basic-url" aria-describedby="basic-addon3" name='permalink' onChange={(e) => {
                                    setForm({
                                        ...formVals,
                                        sp: {
                                            ...formVals?.sp,
                                            heading: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en" style={{ fontWeight: '800' }}>Answer</label>
                        </div>
                    </div>
                    <div className='col-6'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en">English Answer</label>
                            <p onClick={() => {
                                setNumber({
                                    number: 3,
                                    state: true
                                })
                            }}><i className='bi bi-pencil'></i></p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formVals?.en?.content }} className='border content-image p-3'></p>
                    </div>
                    <div className='col-6'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en">Spanish Answer</label>
                            <p onClick={() => {
                                setNumber({
                                    number: 4,
                                    state: true
                                })
                            }}><i className='bi bi-pencil'></i></p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formVals?.sp?.content }} className='border content-image p-3'></p>
                    </div>
                    <button type="button" onClick={() => submitForm()} className="btn btn-primary">Add Faq</button>
                </div>
            </section>

            {modalnum.number === 3 ? <Popup value={formVals?.en?.content} number={modalnum.number} onClose={() => {
                setNumber({
                    number: 3,
                    state: false
                })
            }} className={modalnum.state ? 'show' : ''} onChange={(value) => {
                setForm({
                    ...formVals,
                    en: {
                        ...formVals?.en,
                        content: value
                    }
                })
            }} /> : null}
            {modalnum.number === 4 ? <Popup value={formVals?.sp?.content} number={modalnum.number} onClose={() => {
                setNumber({
                    number: 4,
                    state: false
                })
            }} className={modalnum.state ? 'show' : ''} onChange={(value) => {
                setForm({
                    ...formVals,
                    sp: {
                        ...formVals?.sp,
                        content: value
                    }
                })
            }} /> : null}
        </main>
    )
}

export default AddFaq