import React, { useState, useEffect } from 'react'
import axios from 'axios'
import API from './Api'
import { useNavigate } from 'react-router-dom';



export default function Home() {
  const [isFetch, setFetch] = useState(true);
  const router = useNavigate()
  const [fullname, setfullname] = useState({
    main: "",
    wish: "",
    button: "",
    message: "",
    model1: "",
    model2: "",
    model3: "",
    upper: "",
    bottom1: "",
    bottom2: "",
    build: "",
    reply1: "",
    reply2: "",
    que: "",
    year1: '',
    year2: '',
    year3: '',
    year4: '',
    yeardata1: '',
    yeardata2: '',
    yeardata3: '',
    yeardata4: '',

    main1: "",
    wish1: "",
    button1: "",
    message1: "",
    model11: "",
    model21: "",
    model31: "",
    upper1: "",
    bottom11: "",
    bottom21: "",
    build1: "",
    reply11: "",
    reply21: "",
    que1: "",
    year11: '',
    year21: '',
    year31: '',
    year41: '',
    yeardata11: '',
    yeardata21: '',
    yeardata31: '',
    yeardata41: '',
  });

  useEffect(() => {
    async function getRepo() {
      const res = await axios.get(`${API.BASE_URL}/home/sections`)
        .then((res) => {
          const datas = res.data;
          let obj = {
            ...datas.data[0].en,
            main1: datas.data[0].sp.main,
            wish1: datas.data[0].sp.wish,
            button1: datas.data[0].sp.button,
            message1: datas.data[0].sp.message,
            model11: datas.data[0].sp.model1,
            model21: datas.data[0].sp.model2,
            model31: datas.data[0].sp.model3,
            upper1: datas.data[0].sp.upper,
            bottom11: datas.data[0].sp.bottom1,
            bottom21: datas.data[0].sp.bottom2,
            build1: datas.data[0].sp.build,
            reply11: datas.data[0].sp.reply1,
            reply21: datas.data[0].sp.reply2,
            que1: datas.data[0].sp.que,
            year11: datas.data[0].sp.year1,
            year21: datas.data[0].sp.year2,
            year31: datas.data[0].sp.year3,
            year41: datas.data[0].sp.year4
          }
          setfullname(obj)
          setFetch(false)
        });
    }
    getRepo()
  }, [])


  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    let obj = {
      _id: '62cd67a3b66669956dadb1f4',
      name: "home",
      en: {
        main: fullname.main,
        wish: fullname.wish,
        button: fullname.button,
        message: fullname.message,
        model1: fullname.model1,
        model2: fullname.model2,
        model3: fullname.model3,
        upper: fullname.upper,
        bottom1: fullname.bottom1,
        bottom2: fullname.bottom2,
        build: fullname.build,
        reply1: fullname.reply1,
        reply2: fullname.reply2,
        que: fullname.que,
        year1: fullname.year1,
        year2: fullname.year2,
        year3: fullname.year3,
        year4: fullname.year4
      },
      sp: {
        main: fullname.main1,
        wish: fullname.wish1,
        button: fullname.button1,
        message: fullname.message1,
        model1: fullname.model11,
        model2: fullname.model21,
        model3: fullname.model31,
        upper: fullname.upper1,
        bottom1: fullname.bottom11,
        bottom2: fullname.bottom21,
        build: fullname.build1,
        reply1: fullname.reply11,
        reply2: fullname.reply21,
        que: fullname.que1,
        year1: fullname.year11,
        year2: fullname.year21,
        year3: fullname.year31,
        year4: fullname.year41
      },

    }
    async function sendData() {
      const res = await axios.put(`${API.BASE_URL}/home/section`, obj, {
        headers: {
          'x-token': localStorage.getItem('token')
        }
      })
        .then((res) => {
          if (res.data.status) {
            alert("Data Updated")
          } else {
            if (res.data.code === 401) {
              localStorage.clear();
              router('/login')
            }
          }
        }).catch((err) => {
          console.log(err.message)
        })
    }
    sendData();
  }


  return (
    <>
      <main id="main" className="main">

        <section className="section">
          <div className="row">
            <div className="col-lg-6">
              <div className="row mb-3">
                <h3>English</h3>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <h3>Spanish</h3>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">main</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.main} name='main' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.main1} name='main1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">wish</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.wish} name='wish' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.wish1} name='wish1' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">button</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.button} name='button' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.button1} name='button1' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">message</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.message} name='message' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.message1} name='message1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">model1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.model1} name='model1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.model11} name='model11' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">model2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.model2} name='model2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.model21} name='model21' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">model3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.model3} name='model3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.model31} name='model31' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">upper</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.upper} name='upper' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.upper1} name='upper1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">bottom1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.bottom1} name='bottom1' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.bottom11} name='bottom11' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">bottom2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.bottom2} name='bottom2' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.bottom21} name='bottom21' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">build</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.build} name='build' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.build1} name='build1' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">reply1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.reply1} name='reply1' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.reply11} name='reply11' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">reply2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.reply2} name='reply2' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.reply21} name='reply21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">que</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.que} name='que' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.que1} name='que1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">year1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year1} name='year1' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year11} name='year11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">year2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year2} name='year2' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year21} name='year21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">year3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year3} name='year3' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year31} name='year31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">year4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year4} name='year4' />
                </div>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.year41} name='year41' />
                </div>
              </div>
            </div>
            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button>
          </div>
        </section>

      </main>
    </>

  )

}