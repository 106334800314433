import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API from './Api'


export default function EditProfile() {
  const [isFetch, setFetch] = useState(false);
  const [mainfile, setFile] = useState();
  const router = useNavigate();
  const [fullname, setfullname] = useState({
    name: "",
    email: "",
    username: "",
    profilePic: "",
  });

  useEffect(() => {
    if (!isFetch) {
      setfullname({
        name: localStorage.getItem("name"),
        email: localStorage.getItem("email"),
        username: localStorage.getItem("username"),
        profilePic: localStorage.getItem("profilePic")
      });
    }
    setFetch(true);
  });

  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const changeFile = (event) => {
    setFile(event.target.files[0]);

    const base64 = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(mainfile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
    base64.then((res)=>{
        setfullname({
            profilePic: res,
          });
    }).catch((err)=>{
        console.log(err.message,"this is error")
    })
    
  };

  const buttonclickevent = (event) => {
    event.preventDefault();
    let formData = new FormData();
    formData.append('name',fullname.name);
    formData.append('username',fullname.username);
    formData.append('email',fullname.email);
    if(mainfile != undefined){
        formData.append('profilePic',mainfile);
    }
    formData.append('accessToken',localStorage.getItem("token"))
    axios.post(`${API.BASE_URL}/admin/editProfile`,formData).then((res)=>{
    if(res.data.status){
        console.log(res,"this is resposne ")
        localStorage.setItem('email',res.data.data.email);
        localStorage.setItem('username',res.data.data.username);
        localStorage.setItem('token',res.data.data.accessToken);
        localStorage.setItem('name',res.data.data.name);
        localStorage.setItem('profilePic',res.data.data.profilePic);
        setfullname(res.data.data);
        document.getElementById("file").value = null;
        window.alert(res.data.message);
    }else{
      localStorage.clear();
      window.alert("Session expired, Please login again");
      router('/login');
    }
    }).catch((err)=>{
        window.alert(err.message);
    })
  };

  return (
    <>
      <main id="main" className="main">
        <section className="section avatarsec">
          <div className="row avatar">
            <input id="file" type="file" onChange={changeFile} />
            <img src={`${API.BASE_URL}/uploads/${fullname.profilePic}`} className="img-fluid" />
          </div>
          <br />
          <br />
          <div className="row">
            <div className="col-lg-12">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-12 col-form-label">
                  Name
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={inputevent}
                    value={fullname.name}
                    name="name"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-12 col-form-label">
                  Username
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={inputevent}
                    value={fullname.username}
                    name="username"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-12 col-form-label">
                  Email
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={inputevent}
                    value={fullname.email}
                    name="email"
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary submmit"
              onClick={buttonclickevent}
            >
              Save Data
            </button>
          </div>
        </section>
      </main>
    </>
  );
}
