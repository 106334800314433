import React, { useState, useEffect } from 'react'
import axios from 'axios'
import API from './Api'
import { useNavigate } from 'react-router-dom';



export default function Ai() {
  const [isFetch, setFetch] = useState(true);
  const router = useNavigate()
  const [fullname, setfullname] = useState({
    para1: '',
    para2: '',
    para3: '',
    head1: '',
    headdesc1: '',
    btn1: '',
    btn2: '',
    btn3: '',
    btn2des: '',
    btn3des: '',
    big: '',
    li1: '',
    li2: '',
    li3: '',
    li4: '',
    li5: '',
    li6: '',
    main1: '',
    main2: '',
    box1: '',
    boxdesc1: '',
    box2: '',
    boxdesc2: '',
    box3: '',
    boxdesc3: '',
    box4: '',
    boxdesc4: '',
    box5: '',
    boxdesc5: '',
    box6: '',
    boxdesc6: '',
    blue1: '',
    bluedesc: '',
    head2: '',
    down1: '',
    head3: '',
    down2: '',
    frmwrk: '',
    box7: '',
    box8: '',
    quw: '',
    lastbtn: '',

    para11: '',
    para21: '',
    para31: '',
    head11: '',
    headdesc11: '',
    btn11: '',
    btn21: '',
    btn31: '',
    btn2des1: '',
    btn3des1: '',
    big1: '',
    li11: '',
    li21: '',
    li31: '',
    li41: '',
    li51: '',
    li61: '',
    main11: '',
    main21: '',
    box11: '',
    boxdesc11: '',
    box21: '',
    boxdesc21: '',
    box31: '',
    boxdesc31: '',
    box41: '',
    boxdesc41: '',
    box51: '',
    boxdesc51: '',
    box61: '',
    boxdesc61: '',
    blue11: '',
    bluedesc1: '',
    head21: '',
    down11: '',
    head31: '',
    down21: '',
    frmwrk1: '',
    box71: '',
    box81: '',
    quw1: '',
    lastbtn1: '',
  });

  useEffect(() => {
    async function getRepo() {
      const res = await axios.get(`${API.BASE_URL}/home/ais`)
        .then((res) => {
          const datas = res.data;
          console.log(datas.data[0], "this is dataas")
          let obj = {
            ...datas.data[0].en,
            para11: datas.data[0].sp.para1,
            para21: datas.data[0].sp.para2,
            para31: datas.data[0].sp.para3,
            head11: datas.data[0].sp.head1,
            headdesc11: datas.data[0].sp.headdesc1,
            btn11: datas.data[0].sp.btn1,
            btn21: datas.data[0].sp.btn2,
            btn31: datas.data[0].sp.btn3,
            btn2des1: datas.data[0].sp.btn2des,
            btn3des1: datas.data[0].sp.btn3des,
            big1: datas.data[0].sp.big,
            li11: datas.data[0].sp.li1,
            li21: datas.data[0].sp.li2,
            li31: datas.data[0].sp.li3,
            li41: datas.data[0].sp.li4,
            li51: datas.data[0].sp.li5,
            li61: datas.data[0].sp.li6,
            main11: datas.data[0].sp.main1,
            main21: datas.data[0].sp.main2,
            box11: datas.data[0].sp.box1,
            boxdesc11: datas.data[0].sp.boxdesc1,
            box21: datas.data[0].sp.box2,
            boxdesc21: datas.data[0].sp.boxdesc2,
            box31: datas.data[0].sp.box3,
            boxdesc31: datas.data[0].sp.boxdesc3,
            box41: datas.data[0].sp.box4,
            boxdesc41: datas.data[0].sp.boxdesc4,
            box51: datas.data[0].sp.box5,
            boxdesc51: datas.data[0].sp.boxdesc5,
            box61: datas.data[0].sp.box6,
            boxdesc61: datas.data[0].sp.boxdesc6,
            blue11: datas.data[0].sp.blue1,
            bluedesc1: datas.data[0].sp.bluedesc,
            head21: datas.data[0].sp.head2,
            down11: datas.data[0].sp.down1,
            head31: datas.data[0].sp.head3,
            down21: datas.data[0].sp.down2,
            frmwrk1: datas.data[0].sp.frmwrk,
            box71: datas.data[0].sp.box7,
            box81: datas.data[0].sp.box8,
            quw1: datas.data[0].sp.quw,
            lastbtn1: datas.data[0].sp.lastbtn,
          }
          setfullname(obj)
          setFetch(false)
        });
    }
    getRepo();
  }, [])


  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    let obj = {
      _id: "62ce75d53a4d8822fd26027b",
      name: "ai",
      en: {
        para1: fullname.para1,
        para2: fullname.para2,
        para3: fullname.para3,
        head1: fullname.head1,
        headdesc1: fullname.headdesc1,
        btn1: fullname.btn1,
        btn2: fullname.btn2,
        btn3: fullname.btn3,
        btn2des: fullname.btn2des,
        btn3des: fullname.btn3des,
        big: fullname.big,
        li1: fullname.li1,
        li2: fullname.li2,
        li3: fullname.li3,
        li4: fullname.li4,
        li5: fullname.li5,
        li6: fullname.li6,
        main1: fullname.main1,
        main2: fullname.main2,
        box1: fullname.box1,
        boxdesc1: fullname.boxdesc1,
        box2: fullname.box2,
        boxdesc2: fullname.boxdesc2,
        box3: fullname.box3,
        boxdesc3: fullname.boxdesc3,
        box4: fullname.box4,
        boxdesc4: fullname.boxdesc4,
        box5: fullname.box5,
        boxdesc5: fullname.boxdesc5,
        box6: fullname.box6,
        boxdesc6: fullname.boxdesc6,
        blue1: fullname.blue1,
        bluedesc: fullname.bluedesc,
        head2: fullname.head2,
        down1: fullname.down1,
        head3: fullname.head3,
        down2: fullname.down2,
        frmwrk: fullname.frmwrk,
        box7: fullname.box7,
        box8: fullname.box8,
        quw: fullname.quw,
        lastbtn: fullname.lastbtn,
      },
      sp: {
        para1: fullname.para11,
        para2: fullname.para21,
        para3: fullname.para31,
        head1: fullname.head11,
        headdesc1: fullname.headdesc11,
        btn1: fullname.btn11,
        btn2: fullname.btn21,
        btn3: fullname.btn31,
        btn2des: fullname.btn2des1,
        btn3des: fullname.btn3des1,
        big: fullname.big1,
        li1: fullname.li11,
        li2: fullname.li21,
        li3: fullname.li31,
        li4: fullname.li41,
        li5: fullname.li51,
        li6: fullname.li61,
        main1: fullname.main11,
        main2: fullname.main21,
        box1: fullname.box11,
        boxdesc1: fullname.boxdesc11,
        box2: fullname.box21,
        boxdesc2: fullname.boxdesc21,
        box3: fullname.box31,
        boxdesc3: fullname.boxdesc31,
        box4: fullname.box41,
        boxdesc4: fullname.boxdesc41,
        box5: fullname.box51,
        boxdesc5: fullname.boxdesc51,
        box6: fullname.box61,
        boxdesc6: fullname.boxdesc61,
        blue1: fullname.blue11,
        bluedesc: fullname.bluedesc1,
        head2: fullname.head21,
        down1: fullname.down11,
        head3: fullname.head31,
        down2: fullname.down21,
        frmwrk: fullname.frmwrk1,
        box7: fullname.box71,
        box8: fullname.box81,
        quw: fullname.quw1,
        lastbtn: fullname.lastbtn1,
      },

    }
    async function sendData() {
      const res = await axios.put(`${API.BASE_URL}/home/ai`, obj,{
        headers: {
          'x-token': localStorage.getItem('token')
        }
      })
        .then((res) => {
          if (res.data.status) {
            alert("Data Updated")
          } else {
            if (res.data.code === 401) {
              localStorage.clear();
              router('/login')
            }
          }
        }).catch((err) => {
          console.log(err.message)
        })
    }
    sendData();
  }


  return (
    <>
      <main id="main" className="main">

        <section className="section">
          <div className="row">
            <div className="col-lg-6">
              <div className="row mb-3">
                <h3>English</h3>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <h3>Spanish</h3>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para1} name='para1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para11} name='para11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para2} name='para2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para21} name='para21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para3} name='para3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para31} name='para31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head1} name='head1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head11} name='head11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">headdesc1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headdesc1} name='headdesc1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headdesc11} name='headdesc11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">btn1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn1} name='btn1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn11} name='btn11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">btn2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn2} name='btn2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn21} name='btn21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">btn3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn3} name='btn3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn31} name='btn31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">btn2des</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn2des} name='btn2des' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn2des1} name='btn2des1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">btn3des</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn3des} name='btn3des' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.btn3des1} name='btn3des1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">big</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.big} name='big' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.big1} name='big1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li1} name='li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li11} name='li11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li2} name='li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li21} name='li21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li3} name='li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li31} name='li31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li4} name='li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li41} name='li41' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li5} name='li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li51} name='li51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li6} name='li6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li61} name='li61' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">main1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.main1} name='main1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.main11} name='main11' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">main2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.main2} name='main2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.main21} name='main21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box1} name='box1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box11} name='box11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxdesc1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc1} name='boxdesc1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc11} name='boxdesc11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box2} name='box2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box21} name='box21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxdesc2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc2} name='boxdesc2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc21} name='boxdesc21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box3} name='box3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box31} name='box31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxdesc3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc3} name='boxdesc3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc31} name='boxdesc31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box4} name='box4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box41} name='box41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxdesc4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc4} name='boxdesc4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc41} name='boxdesc41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box5} name='box5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box51} name='box51' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxdesc5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc5} name='boxdesc5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc51} name='boxdesc51' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box6} name='box6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box61} name='box61' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxdesc6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc6} name='boxdesc6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxdesc61} name='boxdesc61' />
                </div>
              </div>
            </div>




            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">blue1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.blue1} name='blue1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.blue11} name='blue11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">bluedesc</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.bluedesc} name='bluedesc' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.bluedesc1} name='bluedesc1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head2} name='head2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head21} name='head21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">down1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.down1} name='down1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.down11} name='down11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head3} name='head3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head31} name='head31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">down2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.down2} name='down2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.down21} name='down21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">frmwrk</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.frmwrk} name='frmwrk' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.frmwrk1} name='frmwrk1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box7</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box7} name='box7' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box71} name='box71' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">box8</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box8} name='box8' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.box81} name='box81' />
                </div>
              </div>
            </div>


            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button>
          </div>
        </section>

      </main>
    </>

  )

}