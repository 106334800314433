import React from 'react'

const Pagination = ({numbers,next,prev,onChange,skip,limit}) => {
    const generateArray = (n) => {
        let array = []
        for(let i=0;i<n;i++){
            array.push('')
        }
        return array
    }
    let array = generateArray(numbers);
    return (
        <>
            <div style={{ display: 'flex',justifyContent:'center'  }}>
                <i class={prev?"bi bi-chevron-left":"d-none"} onClick={() => {
                    let index = 0;
                    let array = document.getElementsByClassName('click-btn')
                    for(let i=0;i<array.length;i++){
                        if(array[i].classList.contains('button-active')){
                            index = i
                        }
                        array[i].classList.remove('button-active')
                    }
                    if(index > 0){
                        array[index-1].classList.add('button-active')
                    }
                    onChange({
                        skip : skip > 0 ? skip - 10 : 0,
                        limit : limit - 10
                    })
                }}></i>
                <div style={{ display: 'flex',justifyContent:'center' }}>
                    {array.map((element,index)=>{
                        return <button className={index == 0 ? 'click-btn button-active' : 'click-btn'} style={{
                            border: '0px solid', borderRadius: '0px', width: '30px',
                            margin: '0px 5px'
                        }} onClick={(e)=>{
                            for(let item of document.getElementsByClassName('click-btn')){
                                item.classList.remove('button-active')
                            }
                            e.target.classList.add('button-active')
                            onChange({
                                skip : (index*10),
                                limit : ((index+1)*10)
                            })
                        }}>{index+1}</button>
                    })}
                </div>
                <i class={next?"bi bi-chevron-right":"d-none"} onClick={() => {
                    let index = 0;
                    let array = document.getElementsByClassName('click-btn')
                    for(let i=0;i<array.length;i++){
                        if(array[i].classList.contains('button-active')){
                            index = i
                        }
                        array[i].classList.remove('button-active')
                    }
                    if(index+1 < array.length){
                        array[index+1].classList.add('button-active')
                    }
                    
                    onChange({
                        skip : skip + 10,
                        limit : limit + 10
                    })
                }}></i>
            </div>
        </>
    )
}

export default Pagination