import React, { useEffect, useState } from 'react'
import axios from 'axios';
import API from './Api'
import { useNavigate, useParams } from 'react-router-dom';
import Popup from './Popup';

const EditFaq = () => {
    const { id } = useParams()
    const [type, setType] = useState('fintech')
    const [formVals, setForm] = useState({
        en: {
            heading: "",
            content: ""
        },
        sp: {
            heading: "",
            content: ""
        }
    })
    const [modalnum, setNumber] = useState({
        number: 0,
        state: false
    })
    const router = useNavigate()
    useEffect(() => {
        axios.get(`${API.BASE_URL}/faq/details/${id}`).then((res) => {
            if (res.data.status) {
                const { titleen, titlesp, descriptionen, descriptionsp } = res.data.data
                console.log(res?.data?.data," this is the for")
                setType(res?.data?.data?.for)
                setForm({
                    en: {
                        heading: titleen,
                        content: descriptionen
                    },
                    sp: {
                        heading: titlesp,
                        content: descriptionsp
                    }
                })
            } else {
                alert(res.data.message)
            }
        }).catch((err) => {
            alert(err.message)
        })
    }, [id])
    const submitForm = async () => {
        await axios.put(`${API.BASE_URL}/faq/edit?id=${id}`, {
            titleen: formVals?.en?.heading,
            titlesp: formVals?.sp?.heading,
            descriptionen: formVals?.en?.content,
            descriptionsp: formVals?.sp?.content,
            for: type
        }, {
            headers: {
                'x-token': localStorage.getItem('token')
            }
        })
            .then((res) => {
                alert("Faq updated successfully");
                setForm({
                    en: {
                        heading: "",
                        content: ""
                    },
                    sp: {
                        heading: "",
                        content: ""
                    }
                })
                setType('fintech')
                router(-1)
            }).catch((err) => {
                console.log(err.message);
            });
    }
    const updateType = (e) => {
        setType(e.target.value)
    }
    return (
        <main id="main" className="main">
            <section className="section">
                <div className="row">
                    <div className='col-md-12'>
                        <select onChange={updateType} value={type} className="form-control mb-3">
                            <option value={'fintech'}>Fintech</option>
                            <option value={'blockchain'}>Blockchain</option>
                            <option value={'aiml'}>AI/ML</option>
                            <option value={'iot'}>IOT</option>
                            <option value={'arvr'}>AR/VR</option>
                            <option value={'robotics'}>Robotics</option>
                        </select>
                    </div>
                    <div className='col-md-6'>
                        <div className="from-group mb-3">
                            <label htmlFor="name_en">English Question</label>
                            <div class="input-group mb-3">
                                <input type="text" value={formVals?.en?.heading} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='heading' onChange={(e) => {
                                    setForm({
                                        ...formVals,
                                        en: {
                                            ...formVals?.en,
                                            heading: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="from-group mb-3">
                            <label htmlFor="name_en">Spanish Question</label>
                            <div class="input-group mb-3">
                                <input type="text" value={formVals?.sp?.heading} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='permalink' onChange={(e) => {
                                    setForm({
                                        ...formVals,
                                        sp: {
                                            ...formVals?.sp,
                                            heading: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en" style={{ fontWeight: '800' }}>Blog Content</label>
                            <p>Created on : {new Date(formVals?.updatedAt ?? 0).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                        </div>
                    </div>
                    <div className='col-6 content-image'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en">English Answer</label>
                            <p onClick={() => {
                                setNumber({
                                    number: 3,
                                    state: true
                                })
                            }}><i className='bi bi-pencil'></i></p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formVals?.en?.content }} className='border p-3'></p>
                    </div>
                    <div className='col-6 content-image'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en">Spanish Answer</label>
                            <p onClick={() => {
                                setNumber({
                                    number: 4,
                                    state: true
                                })
                            }}><i className='bi bi-pencil'></i></p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formVals?.sp?.content }} className='border p-3'></p>
                    </div>
                    <button type="button" onClick={() => submitForm()} class="btn btn-primary">Update blog</button>
                </div>
            </section>
            {modalnum.number === 3 ? <Popup value={formVals?.en?.content} number={modalnum.number} onClose={() => {
                setNumber({
                    number: 3,
                    state: false
                })
            }} className={modalnum.state ? 'show' : ''} onChange={(value) => {
                setForm({
                    ...formVals,
                    en: {
                        ...formVals?.en,
                        content: value
                    }
                })
            }} /> : null}
            {modalnum.number === 4 ? <Popup value={formVals?.sp?.content} number={modalnum.number} onClose={() => {
                setNumber({
                    number: 4,
                    state: false
                })
            }} className={modalnum.state ? 'show' : ''} onChange={(value) => {
                setForm({
                    ...formVals,
                    sp: {
                        ...formVals?.sp,
                        content: value
                    }
                })
            }} /> : null}
        </main>
    )
}

export default EditFaq