import React from 'react'
import { NavLink } from 'react-router-dom'


export default function Sidebar() {
    return (
        <>
            <aside id="sidebar" className="sidebar">

                <ul className="sidebar-nav" id="sidebar-nav">

                    <li className="nav-item">
                        <NavLink className="nav-link " to="/home">
                            <i className="bi bi-grid"></i>
                            <span>Home</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                            <i className="bi bi-menu-button-wide"></i><span>Services</span><i className="bi bi-chevron-down ms-auto"></i>
                        </a>
                        <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                            <li>
                                <NavLink to="/fintech">
                                    <i className="bi bi-circle"></i><span>FINTECH/ TRADING</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/blockchain">
                                    <i className="bi bi-circle"></i><span>BLOCKCHAIN/ CRYPTO</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/artificial-intelligence">
                                    <i className="bi bi-circle"></i><span>AI/ML/DEEP LEARNING</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/iot">
                                    <i className="bi bi-circle"></i><span>INTERNET OF THINGS</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="/ARV">
                                    <i className="bi bi-circle"></i><span>AR/VR/ GAME DEVELOPMENT</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to="robotics">
                                    <i className="bi bi-circle"></i><span>ROBOTICS/ DRONES</span>
                                </NavLink>
                            </li>
                        </ul>
                    </li>

                    <li className="nav-heading">Pages</li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/expertise">
                            <i className="bi bi-person"></i>
                            <span>Expertise</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/process">
                            <i className="bi bi-question-circle"></i>
                            <span>Process</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/about">
                            <i className="bi bi-envelope"></i>
                            <span>About</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/contact">
                            <i className="bi bi-phone"></i>
                            <span>Contact</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/testimonials">
                        <i class="bi bi-chat-square-quote"></i>
                            <span>Testimonials</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/faqs">
                        <i class="bi bi-chat-square-quote"></i>
                            <span>Faqs</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link collapsed" to="/blogs">
                        <i class="bi bi-chat-square-quote"></i>
                            <span>Blogs</span>
                        </NavLink>
                    </li>

                </ul>

            </aside>
            
        </>
    )
}
