import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import API from './Api'
import Pagination from './Pagination'

const Blogs = () => {
    const router = useNavigate()
    const [list, setList] = useState([])
    const [extraData, setExtraData] = useState({
        skip: 0,
        limit: 10,
        total_count: 0
    })
    const fetchData = () => {
        axios.get(`${API.BASE_URL}/blog/list?skip=0&limit=10`).then((res) => {
            if (res.data.status) {
                setExtraData({
                    skip: 0,
                    limit: 10,
                    total_count: res.data.total_count
                })
                setList([...res.data.data])
            } else {
                alert(res.data.message)
            }
        }).catch((err) => {
            alert(err.message)
        })
    }
    const deleteBlog = (id) => {
        let status = window.confirm("Do you really want to delete this blog ?");
        if (status) {
            axios.delete(`${API.BASE_URL}/blog/delete?id=${id}`).then((res) => {
                if (res.data.status) {
                    alert(res.data.message);
                    setList([...list.filter(e => e._id != id)])
                } else {
                    if (res.data.code === 401) {
                        localStorage.clear();
                        router('/login')
                    }
                }
            }).catch((err) => {
                alert(err.message)
            })
            fetchData()
        }
    }
    const [imageLink, setLink] = useState('')
    const buttonclickevent = (event) => {
        event.preventDefault();
    }
    const getPagination = (numbers) => {
        let total_count = numbers
        let result = total_count / 10
        let round = Math.round(result)
        let total = (result - round) > 0 ? 1 : 0
        let final = round + total
        return final
    }
    useEffect(() => {
        fetchData()
    }, [])

    const fetchMore = (obj) => {
        axios.get(`${API.BASE_URL}/blog/list?skip=${obj.skip}&limit=${obj.limit}`).then((res) => {
            if (res.data.status) {
                setExtraData({
                    ...extraData,
                    skip: obj.skip,
                    limit: obj.limit
                })
                setList([...res.data.data])
            } else {
                alert(res.data.message)
            }
        }).catch((err) => {
            alert(err.message)
        })
    }

    return (
        <main id="main" className="main">
            <section className="section">
                <div className="row">
                    <button className='btn btn-primary w-auto' onClick={() => router('/blogs/add')}>Add Blog</button>
                    <div className="table-responsive">
                        <br />
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Sr.</th>
                                    <th scope="col">Heading (English)</th>
                                    <th scope="col">Heading (Spanish)</th>
                                    <th scope='col'>Image</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list.map((element, index) => {
                                        return <>
                                            <tr>
                                                <th scope="row">{index + 1}</th>
                                                <td>{element.en.heading}</td>
                                                <td>{element.sp.heading}</td>
                                                <td><button className='btn btn-primary' data-bs-toggle="modal" data-bs-target="#viewimage" onClick={() => { setLink(`${API.BASE_URL}/uploads/${element.banner}`) }}>View</button></td>
                                                <td>
                                                    <div className='d-flex'>
                                                        <button className='btn' onClick={() => { router('/blogs/' + element.permalink) }}><i className='bi bi-pencil'></i></button>
                                                        <button className='btn' type='submit' onClick={() => { deleteBlog(element._id) }}><i className='bi bi-trash'></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                            <div class="modal fade" id={element._id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                                <div class="modal-dialog modal-dialog-centered modal-lg">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <div className="from-group mb-3">
                                                                <label htmlFor="name_en">Name Eng</label>
                                                                <input type="text" className='form-control' />
                                                            </div>
                                                            <div className="from-group mb-3">
                                                                <label htmlFor="name_sp">Name Sp</label>
                                                                <input type="text" className='form-control' />
                                                            </div>
                                                            <div className="from-group mb-3">
                                                                <label htmlFor="name_ts_en">Testimonial Eng</label>
                                                                <input type="text" className='form-control' />
                                                            </div>
                                                            <div className="from-group mb-3">
                                                                <label htmlFor="name_ts_sp">Testimonial Sp</label>
                                                                <input type="text" className='form-control' />
                                                            </div>

                                                        </div>
                                                        <div class="modal-footer">
                                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                                            <button type="button" class="btn btn-primary" onClick={buttonclickevent}>Save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    })
                                }
                            </tbody>
                        </table>
                        <Pagination onChange={(obj) => {
                            fetchMore(obj)
                        }} skip={extraData.skip} limit={extraData.limit} numbers={getPagination(extraData.total_count)} next={extraData.limit < extraData.total_count} prev={extraData.skip !== 0} />
                    </div>
                </div>
            </section>
            <div className="modal fade" id="viewimage" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <form>
                            <div className="modal-body text-center">
                                <img src={imageLink} className='img-fluid' />
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={buttonclickevent}>Save</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </main>
    )
}

export default Blogs