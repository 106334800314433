import React, { useState, useEffect } from 'react'
import axios from 'axios'

import API from './Api'
import { useNavigate } from 'react-router-dom';


export default function Internet() {
  const [isFetch, setFetch] = useState(true);
  const router = useNavigate()

  const [fullname, setfullname] = useState({
    para1: '',
    para2: '',
    para3: '',
    head: '',
    top1: '',
    topdesc1: '',
    top2: '',
    topdesc2: '',
    top3: '',
    topdesc3: '',
    top4: '',
    topdesc4: '',
    downhead: '',
    li1: '',
    li2: '',
    li3: '',
    li4: '',
    li5: '',
    li6: '',
    downhead2: '',
    li7: '',
    li8: '',
    li9: '',
    li10: '',
    li01: '',
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    line5: '',
    mainup: '',
    end: '',
    btn: '',

    para11: '',
    para21: '',
    para31: '',
    head1: '',
    top11: '',
    topdesc11: '',
    top21: '',
    topdesc21: '',
    top31: '',
    topdesc31: '',
    top41: '',
    topdesc41: '',
    downhead1: '',
    li11: '',
    li21: '',
    li31: '',
    li41: '',
    li51: '',
    li61: '',
    downhead21: '',
    li71: '',
    li81: '',
    li91: '',
    li101: '',
    li011: '',
    line11: '',
    line21: '',
    line31: '',
    line41: '',
    line51: '',
    mainup1: '',
    end1: '',
    btn1: '',
  });

  useEffect(() => {
    async function getRepo() {
      const res = await axios.get(`${API.BASE_URL}/home/internets`)
        .then((res) => {
          const datas = res.data;
          let obj = {
            ...datas.data[0].en,
            para11: datas.data[0].sp.para1,
            para21: datas.data[0].sp.para2,
            para31: datas.data[0].sp.para3,
            head1: datas.data[0].sp.head,
            top11: datas.data[0].sp.top1,
            topdesc11: datas.data[0].sp.topdesc1,
            top21: datas.data[0].sp.top2,
            topdesc21: datas.data[0].sp.topdesc2,
            top31: datas.data[0].sp.top3,
            topdesc31: datas.data[0].sp.topdesc3,
            top41: datas.data[0].sp.top4,
            topdesc41: datas.data[0].sp.topdesc4,
            downhead1: datas.data[0].sp.downhead,
            li11: datas.data[0].sp.li1,
            li21: datas.data[0].sp.li2,
            li31: datas.data[0].sp.li3,
            li41: datas.data[0].sp.li4,
            li51: datas.data[0].sp.li5,
            li61: datas.data[0].sp.li6,
            downhead21: datas.data[0].sp.downhead2,
            li71: datas.data[0].sp.li7,
            li81: datas.data[0].sp.li8,
            li91: datas.data[0].sp.li9,
            li101: datas.data[0].sp.li10,
            li011: datas.data[0].sp.li01,
            line11: datas.data[0].sp.line1,
            line21: datas.data[0].sp.line2,
            line31: datas.data[0].sp.line3,
            line41: datas.data[0].sp.line4,
            line51: datas.data[0].sp.line5,
            mainup1: datas.data[0].sp.mainup,
            end1: datas.data[0].sp.end,
            btn1: datas.data[0].sp.btn,
          }
          setfullname(obj)
          setFetch(false)
        });
    }
    getRepo();
  }, [])


  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    let obj = {
      _id: "62ce7e39bdc40e2f6be5b8eb",
      name: "internet",
      en: {
        para1: fullname.para1,
        para2: fullname.para2,
        para3: fullname.para3,
        head: fullname.head,
        top1: fullname.top1,
        topdesc1: fullname.topdesc1,
        top2: fullname.top2,
        topdesc2: fullname.topdesc2,
        top3: fullname.top3,
        topdesc3: fullname.topdesc3,
        top4: fullname.top4,
        topdesc4: fullname.topdesc4,
        downhead: fullname.downhead,
        li1: fullname.li1,
        li2: fullname.li2,
        li3: fullname.li3,
        li4: fullname.li4,
        li5: fullname.li5,
        li6: fullname.li6,
        downhead2: fullname.downhead2,
        li7: fullname.li7,
        li8: fullname.li8,
        li9: fullname.li9,
        li10: fullname.li10,
        li01: fullname.li01,
        line1: fullname.line1,
        line2: fullname.line2,
        line3: fullname.line3,
        line4: fullname.line4,
        line5: fullname.line5,
        mainup: fullname.mainup,
        end: fullname.end,
        btn: fullname.btn,
      },
      sp: {
        para1: fullname.para11,
        para2: fullname.para21,
        para3: fullname.para31,
        head: fullname.head1,
        top1: fullname.top11,
        topdesc1: fullname.topdesc11,
        top2: fullname.top21,
        topdesc2: fullname.topdesc21,
        top3: fullname.top31,
        topdesc3: fullname.topdesc31,
        top4: fullname.top41,
        topdesc4: fullname.topdesc41,
        downhead: fullname.downhead1,
        li1: fullname.li11,
        li2: fullname.li21,
        li3: fullname.li31,
        li4: fullname.li41,
        li5: fullname.li51,
        li6: fullname.li61,
        downhead2: fullname.downhead21,
        li7: fullname.li71,
        li8: fullname.li81,
        li9: fullname.li91,
        li10: fullname.li101,
        li01: fullname.li011,
        line1: fullname.line11,
        line2: fullname.line21,
        line3: fullname.line31,
        line4: fullname.line41,
        line5: fullname.line51,
        mainup: fullname.mainup1,
        end: fullname.end1,
        btn: fullname.btn1,
      },

    }
    async function sendData() {
      const res = await axios.put(`${API.BASE_URL}/home/internet`, obj,{
        headers: {
          'x-token': localStorage.getItem('token')
        }
      })
        .then((res) => {
          if (res.data.status) {
            alert("Data Updated")
          } else {
            if (res.data.code === 401) {
              localStorage.clear();
              router('/login')
            }
          }
        }).catch((err) => {
          console.log(err.message)
        })
    }
    sendData();
  }


  return (
    <>
      <main id="main" className="main">

        <section className="section">
          <div className="row">
            <div className="col-lg-6">
              <div className="row mb-3">
                <h3>English</h3>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <h3>Spanish</h3>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para1} name='para1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para11} name='para11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para2} name='para2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para21} name='para21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para3} name='para3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para31} name='para31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head} name='head' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head1} name='head1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">top1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top1} name='top1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top11} name='top11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">topdesc1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc1} name='topdesc1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc11} name='topdesc11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">top2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top2} name='top2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top21} name='top21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">topdesc2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc2} name='topdesc2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc21} name='topdesc21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">top3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top3} name='top3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top31} name='top31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">topdesc3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc3} name='topdesc3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc31} name='topdesc31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">top4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top4} name='top4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.top41} name='top41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">topdesc4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc4} name='topdesc4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.topdesc41} name='topdesc41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">downhead</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead} name='downhead' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead1} name='downhead1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li1} name='li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li11} name='li11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li2} name='li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li21} name='li21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li3} name='li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li31} name='li31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li4} name='li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li41} name='li41' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li5} name='li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li51} name='li51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li6} name='li6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li61} name='li61' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">downhead2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead2} name='downhead2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead21} name='downhead21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li7</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li7} name='li7' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li71} name='li71' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li8</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li8} name='li8' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li81} name='li81' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li9</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li9} name='li9' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li91} name='li91' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li10</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li10} name='li10' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li101} name='li101' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">li01</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li01} name='li01' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.li011} name='li011' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">line1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line1} name='line1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line11} name='line11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">line2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line2} name='line2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line21} name='line21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">line3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line3} name='line3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line31} name='line31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">line4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line4} name='line4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line41} name='line41' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">line5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line5} name='line5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.line51} name='line51' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">mainup</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.mainup} name='mainup' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.mainup1} name='mainup1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">maindown</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.end} name='end' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.end1} name='end1' />
                </div>
              </div>
            </div>








            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button>
          </div>
        </section>

      </main>
    </>

  )

}