import React, { useState, useEffect } from 'react'
import axios from 'axios'
import API from './Api'
import { useNavigate } from 'react-router-dom';

export default function About() {
  const [isFetch, setFetch] = useState(true);
  const router = useNavigate()
  const [fullname, setfullname] = useState({
    head1: '',
    head2: '',
    head3: '',
    head4: '',
    head5: '',
    head6: '',
    head7: '',
    head8: '',
    head9: '',
    head10: '',
    headt11: '',
    headt12: '',
    headt13: '',
    headt14: '',
    lefth: '',
    right1: '',
    right2: '',
    que: '',
    btn: '',
    eth: '',
    ethcont: '',

    head11: '',
    head21: '',
    head31: '',
    head41: '',
    head51: '',
    head61: '',
    head71: '',
    head81: '',
    head91: '',
    head101: '',
    headt111: '',
    headt121: '',
    headt131: '',
    headt141: '',
    lefth1: '',
    right11: '',
    right21: '',
    que1: '',
    btn1: '',
    eth1: '',
    ethcont1: '',
  });

  useEffect(() => {
    async function getRepo() {
      const res = await axios.get(`${API.BASE_URL}/home/abouts`)
        .then((res) => {
          const datas = res.data;
          let obj = {
            ...datas.data[0].en,
            head11: datas.data[0].sp.head1,
            head21: datas.data[0].sp.head2,
            head31: datas.data[0].sp.head3,
            head41: datas.data[0].sp.head4,
            head51: datas.data[0].sp.head5,
            head61: datas.data[0].sp.head6,
            head71: datas.data[0].sp.head7,
            head81: datas.data[0].sp.head8,
            head91: datas.data[0].sp.head9,
            head101: datas.data[0].sp.head10,
            headt111: datas.data[0].sp.headt11,
            headt121: datas.data[0].sp.headt12,
            headt131: datas.data[0].sp.headt13,
            headt141: datas.data[0].sp.headt14,
            lefth1: datas.data[0].sp.lefth,
            right11: datas.data[0].sp.right1,
            right21: datas.data[0].sp.right2,
            que1: datas.data[0].sp.que,
            btn1: datas.data[0].sp.btn,
            eth1: datas.data[0].sp.eth,
            ethcont1: datas.data[0].sp.ethcont,
          }
          setfullname(obj)
          setFetch(false)
        });
    }
    getRepo();
  }, [])


  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    let obj = {
      _id: "62ceae39dd6875bc4f5f4b1f",
      name: "about",
      en: {
        head1: fullname.head1,
        head2: fullname.head2,
        head3: fullname.head3,
        head4: fullname.head4,
        head5: fullname.head5,
        head6: fullname.head6,
        head7: fullname.head7,
        head8: fullname.head8,
        head9: fullname.head9,
        head10: fullname.head10,
        headt11: fullname.headt11,
        headt12: fullname.headt12,
        headt13: fullname.headt13,
        headt14: fullname.headt14,
        lefth: fullname.lefth,
        right1: fullname.right1,
        right2: fullname.right2,
        que: fullname.que,
        btn: fullname.btn,
        eth: fullname.eth,
        ethcont: fullname.ethcont,
      },
      sp: {
        head1: fullname.head11,
        head2: fullname.head21,
        head3: fullname.head31,
        head4: fullname.head41,
        head5: fullname.head51,
        head6: fullname.head61,
        head7: fullname.head71,
        head8: fullname.head81,
        head9: fullname.head91,
        head10: fullname.head101,
        headt11: fullname.headt111,
        headt12: fullname.headt121,
        headt13: fullname.headt131,
        headt14: fullname.headt141,
        lefth: fullname.lefth1,
        right1: fullname.right11,
        right2: fullname.right21,
        que: fullname.que1,
        btn: fullname.btn1,
        eth: fullname.eth1,
        ethcont: fullname.ethcont1,
      }
    }
    async function sendData() {
      const res = await axios.put(`${API.BASE_URL}/home/about`, obj,{
        headers: {
          'x-token': localStorage.getItem('token')
        }
      })
        .then((res) => {
          if (res.data.status) {
            alert("Data Updated")
          } else {
            if (res.data.code === 401) {
              localStorage.clear();
              router('/login')
            }
          }
        }).catch((err) => {
          console.log(err.message)
        })
    }
    sendData();
  }


  return (
    <>
      <main id="main" className="main">

        <section className="section">
          <div className="row">
            <div className="col-lg-6">
              <div className="row mb-3">
                <h3>English</h3>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <h3>Spanish</h3>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head1} name='head1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head11} name='head11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head2} name='head2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head21} name='head21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head3} name='head3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head31} name='head31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head4} name='head4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head41} name='head41' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head5} name='head5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head51} name='head51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head6} name='head6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head61} name='head61' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head7</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head7} name='head7' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head71} name='head71' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head8</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head8} name='head8' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head81} name='head81' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head9</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head9} name='head9' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head91} name='head91' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head10</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head10} name='head10' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head101} name='head101' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">headt11</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt11} name='headt11' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt111} name='headt111' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">headt12</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt12} name='headt12' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt121} name='headt121' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">headt13</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt13} name='headt13' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt131} name='headt131' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">headt14</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt14} name='headt14' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.headt141} name='headt141' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">lefth</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.lefth} name='lefth' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.lefth1} name='lefth1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">right1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right1} name='right1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right11} name='right11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">right2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right2} name='right2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.right21} name='right21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">ethic</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.eth} name='eth' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.eth1} name='eth1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">ethcont</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.ethcont} name='ethcont' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.ethcont1} name='ethcont1' />
                </div>
              </div>
            </div>







            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button>
          </div>
        </section>

      </main>
    </>

  )

}