import React, { useState, useEffect } from 'react'
import axios from 'axios'
import API from './Api'
import { useNavigate } from 'react-router-dom';



export default function Robotic() {
  const [isFetch, setFetch] = useState(true);
  const [type, setType] = useState('fintech')
  const router = useNavigate()
  const [fullname, setfullname] = useState({
    titleen: "",
    titlesp: "",
    descriptionen: "",
    descriptionsp: ""
  });
  const [list, setList] = useState([]);
  const [modal, setmodal] = useState('');
  async function getRepo() {
    const res = await axios.get(`${API.BASE_URL}/faq/data/${type}`)
      .then((res) => {
        const datas = res.data.data;
        setList([...datas]);
        setFetch(false)
        for (var i in datas) {
          setmodal(datas[i]._id);
        }
      });
  }

  useEffect(() => {
    getRepo();
  }, []);

  const [testform, setTestfom] = useState({
    titleen: "",
    titlesp: "",
    descriptionen: "",
    descriptionsp: ""
  });

  const editinput = (event) => {
    const { value, name } = event.target;
    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })
  }

  const showAlert = (name) => {
    alert(name + " can not be blank");
  }

  const inputeventadd = (event) => {
    const { value, name } = event.target;
    setTestfom((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })
  }

  const deleteTestimonial = async (id) => {
    await axios.delete(`${API.BASE_URL}/faq/delete?id=${id}`, {
      headers: {
        'x-token': localStorage.getItem('token')
      }
    })
      .then((res) => {
        alert(res.data.message);
        getRepo();
      }).catch((err) => {
        console.log(err.message)
      })
  }

  const setValues = (id) => {
    let values = list.filter(element => element._id == id)
    setfullname(values[0]);
  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    if (testform.titleen.trim() === "") {
      showAlert("Question name")
    } else if (testform.titlesp.trim() === "") {
      showAlert("Question name")
    } else if (testform.descriptionen.trim() === "") {
      showAlert("Answer message")
    } else if (testform.descriptionsp.trim() === "") {
      showAlert("Answer message")
    } else {
      async function sendData() {
        await axios.post(`${API.BASE_URL}/faq/add`, { ...testform, for: type }, {
          headers: {
            'x-token': localStorage.getItem('token')
          }
        })
          .then((res) => {
            if (res.data.status) {
              alert("Faq added successfully");
              getRepo();
            } else {
              if (res.data.code === 401) {
                localStorage.clear();
                router('/login')
              }
            }
          }).catch((err) => {
            console.log(err.message);
          });
      }
      sendData();
      setTestfom({
        titleen: "",
        titlesp: "",
        descriptionen: "",
        descriptionsp: ""
      });
    }
  }

  const updateValues = (event) => {
    event.preventDefault();
    if (fullname.titleen.trim() == "") {
      showAlert("Question name")
    } else if (fullname.titlesp.trim() == "") {
      showAlert("Question name")
    } else if (fullname.descriptionen.trim() == "") {
      showAlert("Answer message")
    } else if (fullname.descriptionsp.trim() == "") {
      showAlert("Answer message")
    } else {
      let selectedId = fullname._id;
      delete fullname._id;
      async function updateData() {
        await axios.put(`${API.BASE_URL}/faq/edit?id=${selectedId}`, { ...fullname, for: type }, {
          headers: {
            'x-token': localStorage.getItem('token')
          }
        })
          .then((res) => {
            alert("Faq updated successfully");
            getRepo();
          }).catch((err) => {
            console.log(err.message);
          });
      }
      updateData();
    }
  }

  const updateType = (e) => {
    setType(e.target.value)
  }

  useEffect(() => {
    getRepo();
  }, [type])

  return (
    <>
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            {/* modal  */}
            <div className='d-flex justify-content-between faqs'>
              <select onChange={updateType}>
                <option value={'fintech'}>Fintech</option>
                <option value={'blockchain'}>Blockchain</option>
                <option value={'aiml'}>AI/ML</option>
                <option value={'iot'}>IOT</option>
                <option value={'arvr'}>AR/VR</option>
                <option value={'robotics'}>Robotics</option>
              </select>
              <button type="button" className="btn btn-primary" style={{ "marginLeft": "auto" }} onClick={()=>{
                router('/faqs/add')
              }}>
                <i class="bi bi-plus-square"></i>&nbsp;&nbsp;&nbsp;Add Faq
              </button>
            </div>

            <div className="modal fade" id="test" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 class="modal-title">Add FAQ for {type.toUpperCase()}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form>
                    <div className="modal-body">
                      <div className="from-group mb-3">
                        <label htmlFor="name_en">Question Eng</label>
                        <input onChange={inputeventadd} name="titleen" value={testform.titleen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_sp">Question Sp</label>
                        <input onChange={inputeventadd} name="titlesp" value={testform.titlesp} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_en">Answer Eng</label>
                        <input onChange={inputeventadd} name="descriptionen" value={testform.descriptionen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_sp">Answer Sp</label>
                        <input onChange={inputeventadd} name="descriptionsp" value={testform.descriptionsp} type="text" className='form-control' />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={buttonclickevent}>Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal fade" id="test1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 class="modal-title">Edit FAQ for {type.toUpperCase()}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form>
                    <div className="modal-body">
                      <div className="from-group mb-3">
                        <label htmlFor="name_en">Question Eng</label>
                        <input onChange={editinput} name="titleen" value={fullname.titleen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_sp">Question Sp</label>
                        <input onChange={editinput} name="titlesp" value={fullname.titlesp} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_en">Answer Eng</label>
                        <input onChange={editinput} name="descriptionen" value={fullname.descriptionen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_sp">Answer Sp</label>
                        <input onChange={editinput} name="descriptionsp" value={fullname.descriptionsp} type="text" className='form-control' />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={updateValues}>Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="table-responsive">
              <br />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.</th>
                    <th scope="col">Question (English)</th>
                    <th scope="col">Question (Spanish)</th>
                    <th scope="col">Answer (English)</th>
                    <th scope="col">Answer (Spanish)</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    list.length > 0 ? list.map((element, index) => {
                      return <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{element.titleen}</td>
                          <td>{element.titlesp}</td>
                          <td>{element.descriptionen}</td>
                          <td>{element.descriptionsp}</td>
                          <td>
                            <button className='btn' onClick={() => { router('/faqs/'+element._id) }} ><i className='bi bi-pencil'></i></button>
                            <button className='btn' type='submit' onClick={() => { deleteTestimonial(element._id) }}><i className='bi bi-trash'></i></button>
                          </td>
                        </tr>
                        <div class="modal fade" id={element._id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <div className="from-group mb-3">
                                  <label htmlFor="name_en">Question Eng</label>
                                  <input type="text" className='form-control' />
                                </div>
                                <div className="from-group mb-3">
                                  <label htmlFor="name_sp">Question Sp</label>
                                  <input type="text" className='form-control' />
                                </div>
                                <div className="from-group mb-3">
                                  <label htmlFor="name_ts_en">Answer Eng</label>
                                  <input type="text" className='form-control' />
                                </div>
                                <div className="from-group mb-3">
                                  <label htmlFor="name_ts_sp">Answer Sp</label>
                                  <input type="text" className='form-control' />
                                </div>

                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" onClick={buttonclickevent}>Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    }) : null
                  }
                </tbody>
              </table>
              <p className={list.length <= 0 ? 'text-center' : 'd-none'}>No Faq added yet for {type.toUpperCase()}</p>
            </div>
          </div>

        </section>

      </main>
    </>

  )

}