import React, { useState } from "react";
import "./index.css";
import { useNavigate, Link } from "react-router-dom";
import Header from './Header';
import Sidebar from './Sidebar';
import Linking from './Linking';
import axios from "axios";
import API from './Api'


export default function Recover() {
  const [logindetails, setLoginDetails] = useState({ email: "" });
  const router = useNavigate();
  const inputevent = (event) => {
    const { value, name } = event.target;
    setLoginDetails((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const sendPassword = (event) => {
    event.preventDefault();
    axios.post(`${API.BASE_URL}/admin/forgotPassword`, logindetails).then((res) => {
      console.log(res, "thjis is  respose")
      window.alert(res.data.message);
      router('/login')
    }).catch((err) => {
      window.alert(err.message);
    })
  }

  return (
    <>
      <div className="container">
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                <div className="d-flex justify-content-center py-4">
                  <a href="index.html" className="logo d-flex align-items-center w-auto">
                    <img src="assets/img/logo.png" alt="" />
                    <span className="d-none d-lg-block">Ana Gay</span>
                  </a>
                </div>
                <div className="card mb-3">

                  <div className="card-body">

                    <div className="pt-4 pb-2">
                      <h5 className="card-title text-center pb-0 fs-4">Recover Your Account</h5>
                      <p className="text-center small">Enter your email to recover your password</p>
                    </div>

                    <form className="row g-3 needs-validation" novalidate>

                      <div className="col-12">
                        <label for="yourUsername" className="form-label">Email</label>
                        <div className="input-group has-validation">
                          <input type="email" name="email" className="form-control" onChange={inputevent} id="yourUsername" required />
                          <div className="invalid-feedback">Please enter your username.</div>
                        </div>
                      </div>
                      <div className="col-12">
                        <button className="btn btn-primary w-100" onClick={sendPassword} type="submit">Send Password</button>
                      </div>
                      <div className="col-12">
                        <p className="small mb-0">Back to login <Link to="/login">Click here</Link></p>
                      </div>
                    </form>

                  </div>
                </div>

              </div>
            </div>
          </div>

        </section>

      </div>
    </>
  );
}
