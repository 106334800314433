import React, { useState, useEffect } from 'react'
import axios from 'axios'
import API from './Api'
import { useNavigate } from 'react-router-dom';



export default function Robotic() {
  const [isFetch, setFetch] = useState(true);
  const router = useNavigate()
  const [fullname, setfullname] = useState({
    nameen: "",
    namesp: "",
    messageen: "",
    messagesp: ""
  });
  const [list, setList] = useState([]);
  const [modal, setmodal] = useState('');
  async function getRepo() {
    const res = await axios.get(`${API.BASE_URL}/testimonial/list`)
      .then((res) => {
        const datas = res.data.data;
        setList([...datas]);
        setFetch(false)
        for (var i in datas) {
          setmodal(datas[i]._id);
        }
      });
  }

  useEffect(() => {
    getRepo();
  }, []);

  const [testform, setTestfom] = useState({
    nameen: "",
    namesp: "",
    messageen: "",
    messagesp: ""
  });

  const editinput = (event) => {
    const { value, name } = event.target;
    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })
  }

  const showAlert = (name) => {
    alert(name + " can not be blank");
  }

  const inputeventadd = (event) => {
    const { value, name } = event.target;
    setTestfom((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })
  }

  const deleteTestimonial = async (id) => {
    await axios.delete(`${API.BASE_URL}/testimonial/delete?id=${id}`,{
      headers: {
        'x-token': localStorage.getItem('token')
      }
    })
      .then((res) => {
        if (res.data.status) {
          alert(res.data.message);
          getRepo();
        } else {
          if (res.data.code === 401) {
            localStorage.clear();
            router('/login')
          }
        }
      }).catch((err) => {
        console.log(err.message)
      })
  }

  const setValues = (id) => {
    let values = list.filter(element => element._id == id)
    setfullname(values[0]);
  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    if (testform.nameen.trim() == "") {
      showAlert("English name")
    } else if (testform.namesp.trim() == "") {
      showAlert("Spanish name")
    } else if (testform.messageen.trim() == "") {
      showAlert("English message")
    } else if (testform.messagesp.trim() == "") {
      showAlert("Spanish message")
    } else {
      setTestfom({
        nameen: "",
        namesp: "",
        messageen: "",
        messagesp: ""
      });
      async function sendData() {
        await axios.post(`${API.BASE_URL}/testimonial/add`, testform,{
          headers: {
            'x-token': localStorage.getItem('token')
          }
        })
          .then((res) => {
            if (res.data.status) {
              alert("Testimonial added successfully");
              getRepo();
            } else {
              if (res.data.code === 401) {
                localStorage.clear();
                router('/login')
              }
            }
          }).catch((err) => {
            console.log(err.message);
          });
      }
      sendData();
    }
  }

  const updateValues = (event) => {
    event.preventDefault();
    if (fullname.nameen.trim() == "") {
      showAlert("English name")
    } else if (fullname.namesp.trim() == "") {
      showAlert("Spanish name")
    } else if (fullname.messageen.trim() == "") {
      showAlert("English message")
    } else if (fullname.messagesp.trim() == "") {
      showAlert("Spanish message")
    } else {
      let selectedId = fullname._id;
      delete fullname._id;
      async function updateData() {
        await axios.put(`${API.BASE_URL}/testimonial/edit?id=${selectedId}`, fullname,{
          headers: {
            'x-token': localStorage.getItem('token')
          }
        })
          .then((res) => {
            if (res.data.status) {
              alert("Testimonial updated successfully");
              getRepo();
            } else {
              if (res.data.code === 401) {
                localStorage.clear();
                router('/login')
              }
            }
          }).catch((err) => {
            console.log(err.message);
          });
      }
      updateData();
    }
  }

  return (
    <>
      <main id="main" className="main">
        <section className="section">
          <div className="row">
            {/* modal  */}
            <button type="button" className="btn btn-primary" style={{ "width": "fit-content", "marginLeft": "auto" }} data-bs-toggle="modal" data-bs-target="#test">
              <i class="bi bi-plus-square"></i>&nbsp;&nbsp;&nbsp;Add Testimonial
            </button>

            <div className="modal fade" id="test" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form>
                    <div className="modal-body">
                      <div className="from-group mb-3">
                        <label htmlFor="name_en">Name Eng</label>
                        <input onChange={inputeventadd} name="nameen" value={testform.nameen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_sp">Name Sp</label>
                        <input onChange={inputeventadd} name="namesp" value={testform.namesp} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_en">Testimonial Eng</label>
                        <input onChange={inputeventadd} name="messageen" value={testform.messageen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_sp">Testimonial Sp</label>
                        <input onChange={inputeventadd} name="messagesp" value={testform.messagesp} type="text" className='form-control' />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={buttonclickevent}>Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="modal fade" id="test1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <form>
                    <div className="modal-body">
                      <div className="from-group mb-3">
                        <label htmlFor="name_en">Name Eng</label>
                        <input onChange={editinput} name="nameen" value={fullname.nameen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_sp">Name Sp</label>
                        <input onChange={editinput} name="namesp" value={fullname.namesp} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_en">Testimonial Eng</label>
                        <input onChange={editinput} name="messageen" value={fullname.messageen} type="text" className='form-control' />
                      </div>
                      <div className="from-group mb-3">
                        <label htmlFor="name_ts_sp">Testimonial Sp</label>
                        <input onChange={editinput} name="messagesp" value={fullname.messagesp} type="text" className='form-control' />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="submit" className="btn btn-primary" data-bs-dismiss="modal" onClick={updateValues}>Save</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            {/* modal  */}

            {/* <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para1} name='para1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para11} name='para11' />
                </div>
              </div>
            </div>

            
           
           
    

            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button> */}

            <div className="table-responsive">
              <br />
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.</th>
                    <th scope="col">Name (English)</th>
                    <th scope="col">Name (Spanish)</th>
                    <th scope="col">Message (English)</th>
                    <th scope="col">Message (Spanish)</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {

                    list.map((element, index) => {
                      return <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{element.nameen}</td>
                          <td>{element.namesp}</td>
                          <td>{element.messageen}</td>
                          <td>{element.messagesp}</td>
                          <td>
                            <button className='btn' onClick={() => { setValues(element._id) }} data-bs-target={'#test1'} data-bs-toggle="modal"><i className='bi bi-pencil'></i></button>
                            <button className='btn' type='submit' onClick={() => { deleteTestimonial(element._id) }}><i className='bi bi-trash'></i></button>
                          </td>
                        </tr>
                        <div class="modal fade" id={element._id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                              <div class="modal-header">
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <div className="from-group mb-3">
                                  <label htmlFor="name_en">Name Eng</label>
                                  <input type="text" className='form-control' />
                                </div>
                                <div className="from-group mb-3">
                                  <label htmlFor="name_sp">Name Sp</label>
                                  <input type="text" className='form-control' />
                                </div>
                                <div className="from-group mb-3">
                                  <label htmlFor="name_ts_en">Testimonial Eng</label>
                                  <input type="text" className='form-control' />
                                </div>
                                <div className="from-group mb-3">
                                  <label htmlFor="name_ts_sp">Testimonial Sp</label>
                                  <input type="text" className='form-control' />
                                </div>

                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                <button type="button" class="btn btn-primary" onClick={buttonclickevent}>Save</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>

        </section>

      </main>
    </>

  )

}