import React, { useEffect, useState } from 'react'
import axios from 'axios';
import API from './Api'
import { useNavigate, useParams } from 'react-router-dom';
import Popup from './Popup';

const EditBlogs = () => {
    const { id } = useParams()
    const [url, setUrl] = useState('')
    const [file, setNewFile] = useState(null)
    const [formVals, setForm] = useState()
    const setFile = (e) => {
        setNewFile(e.target.files[0])
        let link = window.URL.createObjectURL(e.target.files[0])
        setUrl(link)
    }
    const [modalnum, setNumber] = useState({
        number: 0,
        state: false
    })
    const router = useNavigate()
    useEffect(() => {
        axios.get(`${API.BASE_URL}/blog/${id}`).then((res) => {
            if (res.data.status) {
                console.log(res.data.data)
                setForm({ ...res.data.data })
                setUrl(`${API.BASE_URL}/uploads/${res?.data?.data?.banner ?? ''}`)
            } else {

                alert(res.data.message)
            }
        }).catch((err) => {
            alert(err.message)
        })
    }, [id])
    const submitForm = () => {
        let formData = new FormData();
        formData.append('headingen', formVals?.en?.heading)
        formData.append('headingsp', formVals?.sp?.heading)
        formData.append('contenten', formVals?.en?.content)
        formData.append('contentsp', formVals?.sp?.content)
        formData.append('permalink', formVals?.permalink)
        formData.append('metatitle', formVals.metatitle)
        formData.append('metadescription', formVals.metadescription)
        formData.append('canonical', formVals.canonical)
        if (file) {
            formData.append('banner', file);
        }
        axios.patch(`${API.BASE_URL}/blog/update?id=${formVals._id}`, formData,{
            headers: {
              'x-token': localStorage.getItem('token')
            }
          }).then((res) => {
            if (res.data.status) {
                alert("Blog updated successfully");
                router(-1)
            } else {
                if (res.data.code === 401) {
                    router('/login')
                }
                alert(res.data.message)
            }
        }).catch((err) => {
            alert(err.message)
        })
    }
    return (
        <main id="main" className="main">
            <section className="section">
                <div className="row">
                    <div className='col-md-12'>
                        <label for="basic-url">Permalink</label>
                        <div class="input-group mb-3">
                            <div class="input-group-prepend">
                                <span class="input-group-text" id="basic-addon3">https://algocodingexperts.com/blogs/</span>
                            </div>
                            <input type="text" class="form-control" id="basic-url" aria-describedby="basic-addon3" name='permalink' onChange={(e) => {
                                setForm({
                                    ...formVals,
                                    permalink: e.target.value.trim()
                                })
                            }} value={formVals?.permalink} />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="from-group mb-3">
                            <label htmlFor="name_en">English Heading</label>
                            <div class="input-group mb-3">
                                <input type="text" value={formVals?.en?.heading} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='heading' onChange={(e) => {
                                    setForm({
                                        ...formVals,
                                        en: {
                                            ...formVals?.en,
                                            heading: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className="from-group mb-3">
                            <label htmlFor="name_en">Spanish Heading</label>
                            <div class="input-group mb-3">
                                <input type="text" value={formVals?.sp?.heading} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='permalink' onChange={(e) => {
                                    setForm({
                                        ...formVals,
                                        sp: {
                                            ...formVals?.sp,
                                            heading: e.target.value
                                        }
                                    })
                                }} />
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en" style={{ fontWeight: '800' }}>Blog Content</label>
                            <p>Created on : {new Date(formVals?.updatedAt ?? 0).toLocaleDateString('en-us', { year: "numeric", month: "short", day: "numeric" })}</p>
                        </div>
                    </div>
                    <div className='col-6 content-image'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en">English Content</label>
                            <p onClick={() => {
                                setNumber({
                                    number: 3,
                                    state: true
                                })
                            }}><i className='bi bi-pencil'></i></p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formVals?.en?.content }} className='border p-3'></p>
                    </div>
                    <div className='col-6 content-image'>
                        <div style={{ 'display': 'flex', 'justifyContent': 'space-between' }}>
                            <label htmlFor="name_en">Spanish Content</label>
                            <p onClick={() => {
                                setNumber({
                                    number: 4,
                                    state: true
                                })
                            }}><i className='bi bi-pencil'></i></p>
                        </div>
                        <p dangerouslySetInnerHTML={{ __html: formVals?.sp?.content }} className='border p-3'></p>
                    </div>
                    
                    <div className='col-md-12'>
                        <label for="basic-url">Meta Title</label>
                        <div class="input-group mb-3">
                            <input type="text" value={formVals?.metatitle} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='metatitle' onChange={(e) => {
                                setForm({
                                    ...formVals,
                                    metatitle: e.target.value
                                })
                            }} />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label for="basic-url">Meta Description</label>
                        <div class="input-group mb-3">
                            <input type="text" value={formVals?.metadescription} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='metadescription' onChange={(e) => {
                                setForm({
                                    ...formVals,
                                    metadescription: e.target.value
                                })
                            }} />
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label for="basic-url">Meta Canonical</label>
                        <div class="input-group mb-3">
                            <input type="text" value={formVals?.canonical} class="form-control" id="basic-url" aria-describedby="basic-addon3" name='canonical' onChange={(e) => {
                                setForm({
                                    ...formVals,
                                    canonical: e.target.value
                                })
                            }} />
                        </div>
                    </div>
                    <div className="from-group mb-3 mt-3">
                        <label htmlFor="name_en">Banner Image</label>
                        <input accept='.jpg,.png' name="nameen" onChange={setFile} type="file" className='form-control' />
                    </div>
                    <div className={url === '' ? 'd-none' : 'form-group mb-3 mt-2'}>
                        <img alt='banner' className='img-fluid' src={url} />
                    </div>
                    <button type="button" onClick={() => submitForm()} class="btn btn-primary">Update blog</button>
                </div>
            </section>
            {modalnum.number === 3 ? <Popup value={formVals?.en?.content} number={modalnum.number} onClose={() => {
                setNumber({
                    number: 3,
                    state: false
                })
            }} className={modalnum.state ? 'show' : ''} onChange={(value) => {
                setForm({
                    ...formVals,
                    en: {
                        ...formVals?.en,
                        content: value
                    }
                })
            }} /> : null}
            {modalnum.number === 4 ? <Popup value={formVals?.sp?.content} number={modalnum.number} onClose={() => {
                setNumber({
                    number: 4,
                    state: false
                })
            }} className={modalnum.state ? 'show' : ''} onChange={(value) => {
                setForm({
                    ...formVals,
                    sp: {
                        ...formVals?.sp,
                        content: value
                    }
                })
            }} /> : null}
        </main>
    )
}

export default EditBlogs
