import React from 'react'
import { TextEditor } from './TextEditor';

const Popup = ({value,onChange,className,onClose,number}) => {
    return (
        <div className={className !== '' ? "modal big-popup fade "+className : 'd-none'} id="blog" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <label>{parseInt(number) === 1 ? 'English Heading' : parseInt(number) === 2 ? 'Spanish Heading' : parseInt(number) === 3 ? 'English Content' : parseInt(number) === 4 ? 'Spanish Content' : ''}</label>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => {
                            onClose()
                        }}></button>
                    </div>
                    <TextEditor key={'editor1'} content={value ?? ''} num="1" onChange={(value) => {
                        onChange(value ?? '')
                    }} />
                </div>
            </div>
        </div>
    )
}

export default Popup