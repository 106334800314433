import React, { useState, useEffect } from 'react'
import axios from 'axios'
import API from './Api'
import { useNavigate } from 'react-router-dom';



export default function Internet() {
  const [isFetch, setFetch] = useState(true);
  const router = useNavigate()

  const [fullname, setfullname] = useState({
    para1: '',
    para2: '',
    para3: '',
    head: '',
    boxhead1: '',
    boxhead1li1: '',
    boxhead1li2: '',
    boxhead1li3: '',
    boxhead1li4: '',
    boxhead2: '',
    boxhead2li1: '',
    boxhead2li2: '',
    boxhead2li3: '',
    boxhead2li4: '',
    boxhead2li5: '',
    boxhead2li6: '',
    boxhead3: '',
    boxhead3li1: '',
    boxhead3li2: '',
    boxhead3li3: '',
    boxhead3li4: '',
    boxhead3li5: '',
    boxhead3li6: '',
    boxhead4: '',
    boxhead4li1: '',
    boxhead4li2: '',
    boxhead4li3: '',
    boxhead4li4: '',
    boxhead4li5: '',
    boxhead5: '',
    boxhead5li1: '',
    boxhead5li2: '',
    boxhead5li3: '',
    boxhead5li4: '',
    boxhead5li5: '',
    boxhead6: '',
    boxhead6li1: '',
    boxhead6li2: '',
    boxhead6li3: '',
    boxhead6li4: '',
    boxhead6li5: '',
    boxhead7: '',
    boxhead7li1: '',
    boxhead7li2: '',
    boxhead7li3: '',
    boxhead8: '',
    boxhead8li1: '',
    boxhead8li2: '',
    boxhead8li3: '',
    boxhead8li4: '',
    downhead1: '',
    cardh1: '',
    cardh1con: '',
    cardh2: '',
    cardh2con: '',
    cardh3: '',
    cardh3con: '',
    downhead2: '',
    end: '',
    btn: '',

    para11: '',
    para21: '',
    para31: '',
    head1: '',
    boxhead11: '',
    boxhead1li11: '',
    boxhead1li21: '',
    boxhead1li31: '',
    boxhead1li41: '',
    boxhead21: '',
    boxhead2li11: '',
    boxhead2li21: '',
    boxhead2li31: '',
    boxhead2li41: '',
    boxhead2li51: '',
    boxhead2li61: '',
    boxhead31: '',
    boxhead3li11: '',
    boxhead3li21: '',
    boxhead3li31: '',
    boxhead3li41: '',
    boxhead3li51: '',
    boxhead3li61: '',
    boxhead41: '',
    boxhead4li11: '',
    boxhead4li21: '',
    boxhead4li31: '',
    boxhead4li41: '',
    boxhead4li51: '',
    boxhead51: '',
    boxhead5li11: '',
    boxhead5li21: '',
    boxhead5li31: '',
    boxhead5li41: '',
    boxhead5li51: '',
    boxhead61: '',
    boxhead6li11: '',
    boxhead6li21: '',
    boxhead6li31: '',
    boxhead6li41: '',
    boxhead6li51: '',
    boxhead71: '',
    boxhead7li11: '',
    boxhead7li21: '',
    boxhead7li31: '',
    boxhead81: '',
    boxhead8li11: '',
    boxhead8li21: '',
    boxhead8li31: '',
    boxhead8li41: '',
    downhead11: '',
    cardh11: '',
    cardh1con1: '',
    cardh21: '',
    cardh2con1: '',
    cardh31: '',
    cardh3con1: '',
    downhead21: '',
    end1: '',
    btn1: '',
  });

  useEffect(() => {
    async function getRepo() {
      const res = await axios.get(`${API.BASE_URL}/home/games`)
        .then((res) => {
          const datas = res.data;
          let obj = {
            ...datas.data[0].en,
            para11: datas.data[0].sp.para1,
            para11: datas.data[0].sp.para1,
            para21: datas.data[0].sp.para2,
            para31: datas.data[0].sp.para3,
            head1: datas.data[0].sp.head,
            boxhead11: datas.data[0].sp.boxhead1,
            boxhead1li11: datas.data[0].sp.boxhead1li1,
            boxhead1li21: datas.data[0].sp.boxhead1li2,
            boxhead1li31: datas.data[0].sp.boxhead1li3,
            boxhead1li41: datas.data[0].sp.boxhead1li4,
            boxhead21: datas.data[0].sp.boxhead2,
            boxhead2li11: datas.data[0].sp.boxhead2li1,
            boxhead2li21: datas.data[0].sp.boxhead2li2,
            boxhead2li31: datas.data[0].sp.boxhead2li3,
            boxhead2li41: datas.data[0].sp.boxhead2li4,
            boxhead2li51: datas.data[0].sp.boxhead2li5,
            boxhead2li61: datas.data[0].sp.boxhead2li6,
            boxhead31: datas.data[0].sp.boxhead3,
            boxhead3li11: datas.data[0].sp.boxhead3li1,
            boxhead3li21: datas.data[0].sp.boxhead3li2,
            boxhead3li31: datas.data[0].sp.boxhead3li3,
            boxhead3li41: datas.data[0].sp.boxhead3li4,
            boxhead3li51: datas.data[0].sp.boxhead3li5,
            boxhead3li61: datas.data[0].sp.boxhead3li6,
            boxhead41: datas.data[0].sp.boxhead4,
            boxhead4li11: datas.data[0].sp.boxhead4li1,
            boxhead4li21: datas.data[0].sp.boxhead4li2,
            boxhead4li31: datas.data[0].sp.boxhead4li3,
            boxhead4li41: datas.data[0].sp.boxhead4li4,
            boxhead4li51: datas.data[0].sp.boxhead4li5,
            boxhead51: datas.data[0].sp.boxhead5,
            boxhead5li11: datas.data[0].sp.boxhead5li1,
            boxhead5li21: datas.data[0].sp.boxhead5li2,
            boxhead5li31: datas.data[0].sp.boxhead5li3,
            boxhead5li41: datas.data[0].sp.boxhead5li4,
            boxhead5li51: datas.data[0].sp.boxhead5li5,
            boxhead61: datas.data[0].sp.boxhead6,
            boxhead6li11: datas.data[0].sp.boxhead6li1,
            boxhead6li21: datas.data[0].sp.boxhead6li2,
            boxhead6li31: datas.data[0].sp.boxhead6li3,
            boxhead6li41: datas.data[0].sp.boxhead6li4,
            boxhead6li51: datas.data[0].sp.boxhead6li5,
            boxhead71: datas.data[0].sp.boxhead7,
            boxhead7li11: datas.data[0].sp.boxhead7li1,
            boxhead7li21: datas.data[0].sp.boxhead7li2,
            boxhead7li31: datas.data[0].sp.boxhead7li3,
            boxhead81: datas.data[0].sp.boxhead8,
            boxhead8li11: datas.data[0].sp.boxhead8li1,
            boxhead8li21: datas.data[0].sp.boxhead8li2,
            boxhead8li31: datas.data[0].sp.boxhead8li3,
            boxhead8li41: datas.data[0].sp.boxhead8li4,
            downhead11: datas.data[0].sp.downhead1,
            cardh11: datas.data[0].sp.cardh1,
            cardh1con1: datas.data[0].sp.cardh1con,
            cardh21: datas.data[0].sp.cardh2,
            cardh2con1: datas.data[0].sp.cardh2con,
            cardh31: datas.data[0].sp.cardh3,
            cardh3con1: datas.data[0].sp.cardh3con,
            downhead21: datas.data[0].sp.downhead2,
            end1: datas.data[0].sp.end,
            btn1: datas.data[0].sp.btn,
          }
          setfullname(obj)
          setFetch(false)
        });
    }
    getRepo();
  }, [])


  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value
      }
    })

  }

  const buttonclickevent = (event) => {
    event.preventDefault();
    let obj = {
      _id: "62ce9429a098a1b2a1f1a52e",
      name: "games",
      en: {
        para1: fullname.para1,
        para2: fullname.para2,
        para3: fullname.para3,
        head: fullname.head,
        boxhead1: fullname.boxhead1,
        boxhead1li1: fullname.boxhead1li1,
        boxhead1li2: fullname.boxhead1li2,
        boxhead1li3: fullname.boxhead1li3,
        boxhead1li4: fullname.boxhead1li4,
        boxhead2: fullname.boxhead2,
        boxhead2li1: fullname.boxhead2li1,
        boxhead2li2: fullname.boxhead2li2,
        boxhead2li3: fullname.boxhead2li3,
        boxhead2li4: fullname.boxhead2li4,
        boxhead2li5: fullname.boxhead2li5,
        boxhead2li6: fullname.boxhead2li6,
        boxhead3: fullname.boxhead3,
        boxhead3li1: fullname.boxhead3li1,
        boxhead3li2: fullname.boxhead3li2,
        boxhead3li3: fullname.boxhead3li3,
        boxhead3li4: fullname.boxhead3li4,
        boxhead3li5: fullname.boxhead3li5,
        boxhead3li6: fullname.boxhead3li6,
        boxhead4: fullname.boxhead4,
        boxhead4li1: fullname.boxhead4li1,
        boxhead4li2: fullname.boxhead4li2,
        boxhead4li3: fullname.boxhead4li3,
        boxhead4li4: fullname.boxhead4li4,
        boxhead4li5: fullname.boxhead4li5,
        boxhead5: fullname.boxhead5,
        boxhead5li1: fullname.boxhead5li1,
        boxhead5li2: fullname.boxhead5li2,
        boxhead5li3: fullname.boxhead5li3,
        boxhead5li4: fullname.boxhead5li4,
        boxhead5li5: fullname.boxhead5li5,
        boxhead6: fullname.boxhead6,
        boxhead6li1: fullname.boxhead6li1,
        boxhead6li2: fullname.boxhead6li2,
        boxhead6li3: fullname.boxhead6li3,
        boxhead6li4: fullname.boxhead6li4,
        boxhead6li5: fullname.boxhead6li5,
        boxhead7: fullname.boxhead7,
        boxhead7li1: fullname.boxhead7li1,
        boxhead7li2: fullname.boxhead7li2,
        boxhead7li3: fullname.boxhead7li3,
        boxhead8: fullname.boxhead8,
        boxhead8li1: fullname.boxhead8li1,
        boxhead8li2: fullname.boxhead8li2,
        boxhead8li3: fullname.boxhead8li3,
        boxhead8li4: fullname.boxhead8li4,
        downhead1: fullname.downhead1,
        cardh1: fullname.cardh1,
        cardh1con: fullname.cardh1con,
        cardh2: fullname.cardh2,
        cardh2con: fullname.cardh2con,
        cardh3: fullname.cardh3,
        cardh3con: fullname.cardh3con,
        downhead2: fullname.downhead2,
        end: fullname.end,
        btn: fullname.btn,
      },
      sp: {
        para1: fullname.para11,
        para2: fullname.para21,
        para3: fullname.para31,
        head: fullname.head1,
        boxhead1: fullname.boxhead11,
        boxhead1li1: fullname.boxhead1li11,
        boxhead1li2: fullname.boxhead1li21,
        boxhead1li3: fullname.boxhead1li31,
        boxhead1li4: fullname.boxhead1li41,
        boxhead2: fullname.boxhead21,
        boxhead2li1: fullname.boxhead2li11,
        boxhead2li2: fullname.boxhead2li21,
        boxhead2li3: fullname.boxhead2li31,
        boxhead2li4: fullname.boxhead2li41,
        boxhead2li5: fullname.boxhead2li51,
        boxhead2li6: fullname.boxhead2li61,
        boxhead3: fullname.boxhead31,
        boxhead3li1: fullname.boxhead3li11,
        boxhead3li2: fullname.boxhead3li21,
        boxhead3li3: fullname.boxhead3li31,
        boxhead3li4: fullname.boxhead3li41,
        boxhead3li5: fullname.boxhead3li51,
        boxhead3li6: fullname.boxhead3li61,
        boxhead4: fullname.boxhead41,
        boxhead4li1: fullname.boxhead4li11,
        boxhead4li2: fullname.boxhead4li21,
        boxhead4li3: fullname.boxhead4li31,
        boxhead4li4: fullname.boxhead4li41,
        boxhead4li5: fullname.boxhead4li51,
        boxhead5: fullname.boxhead51,
        boxhead5li1: fullname.boxhead5li11,
        boxhead5li2: fullname.boxhead5li21,
        boxhead5li3: fullname.boxhead5li31,
        boxhead5li4: fullname.boxhead5li41,
        boxhead5li5: fullname.boxhead5li51,
        boxhead6: fullname.boxhead61,
        boxhead6li1: fullname.boxhead6li11,
        boxhead6li2: fullname.boxhead6li21,
        boxhead6li3: fullname.boxhead6li31,
        boxhead6li4: fullname.boxhead6li41,
        boxhead6li5: fullname.boxhead6li51,
        boxhead7: fullname.boxhead71,
        boxhead7li1: fullname.boxhead7li11,
        boxhead7li2: fullname.boxhead7li21,
        boxhead7li3: fullname.boxhead7li31,
        boxhead8: fullname.boxhead81,
        boxhead8li1: fullname.boxhead8li11,
        boxhead8li2: fullname.boxhead8li21,
        boxhead8li3: fullname.boxhead8li31,
        boxhead8li4: fullname.boxhead8li41,
        downhead1: fullname.downhead11,
        cardh1: fullname.cardh11,
        cardh1con: fullname.cardh1con1,
        cardh2: fullname.cardh21,
        cardh2con: fullname.cardh2con1,
        cardh3: fullname.cardh31,
        cardh3con: fullname.cardh3con1,
        downhead2: fullname.downhead21,
        end: fullname.end1,
        btn: fullname.btn1,
      },

    }
    async function sendData() {
      const res = await axios.put(`${API.BASE_URL}/home/game`, obj,{
        headers: {
          'x-token': localStorage.getItem('token')
        }
      })
        .then((res) => {
          if (res.data.status) {
            alert("Data Updated")
          } else {
            if (res.data.code === 401) {
              localStorage.clear();
              router('/login')
            }
          }
        }).catch((err) => {
          console.log(err.message)
        })
    }
    sendData();
  }


  return (
    <>
      <main id="main" className="main">

        <section className="section">
          <div className="row">
            <div className="col-lg-6">
              <div className="row mb-3">
                <h3>English</h3>
              </div>
            </div><div className="col-lg-6">
              <div className="row mb-3">
                <h3>Spanish</h3>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para1} name='para1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para11} name='para11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para2} name='para2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para21} name='para21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">para3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para3} name='para3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.para31} name='para31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">head</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head} name='head' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.head1} name='head1' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1} name='boxhead1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead11} name='boxhead11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead1li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li1} name='boxhead1li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li11} name='boxhead1li11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead1li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li2} name='boxhead1li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li21} name='boxhead1li21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead1li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li3} name='boxhead1li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li31} name='boxhead1li31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead1li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li4} name='boxhead1li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead1li41} name='boxhead1li41' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2} name='boxhead2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead21} name='boxhead21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li1} name='boxhead2li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li11} name='boxhead2li11' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li2} name='boxhead2li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li21} name='boxhead2li21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li3} name='boxhead2li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li31} name='boxhead2li31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li4} name='boxhead2li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li41} name='boxhead2li41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li5} name='boxhead2li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li51} name='boxhead2li51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead2li6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li6} name='boxhead2li6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead2li61} name='boxhead2li61' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3} name='boxhead3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead31} name='boxhead31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li1} name='boxhead3li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li11} name='boxhead3li11' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li2} name='boxhead3li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li21} name='boxhead3li21' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li3} name='boxhead3li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li31} name='boxhead3li31' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li4} name='boxhead3li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li41} name='boxhead3li41' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li5} name='boxhead3li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li51} name='boxhead3li51' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead3li6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li6} name='boxhead3li6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead3li61} name='boxhead3li61' />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4} name='boxhead4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead41} name='boxhead41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li1} name='boxhead4li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li11} name='boxhead4li11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li2} name='boxhead4li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li21} name='boxhead4li21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li3} name='boxhead4li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li31} name='boxhead4li31' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li4} name='boxhead4li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li41} name='boxhead4li41' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead4li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li5} name='boxhead4li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead4li51} name='boxhead4li51' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5} name='boxhead5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead51} name='boxhead51' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li1} name='boxhead5li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li11} name='boxhead5li11' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li2} name='boxhead5li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li21} name='boxhead5li21' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li3} name='boxhead5li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li31} name='boxhead5li31' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li4} name='boxhead5li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li41} name='boxhead5li41' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead5li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li5} name='boxhead5li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead5li51} name='boxhead5li51' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6} name='boxhead6' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead61} name='boxhead61' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li1} name='boxhead6li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li11} name='boxhead6li11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li2} name='boxhead6li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li21} name='boxhead6li21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li3} name='boxhead6li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li31} name='boxhead6li31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li4} name='boxhead6li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li41} name='boxhead6li41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead6li5</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li5} name='boxhead6li5' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead6li51} name='boxhead6li51' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead7</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7} name='boxhead7' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead71} name='boxhead71' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead7li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7li1} name='boxhead7li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7li11} name='boxhead7li11' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead7li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7li2} name='boxhead7li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7li21} name='boxhead7li21' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead7li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7li3} name='boxhead7li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead7li31} name='boxhead7li31' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead8</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8} name='boxhead8' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead81} name='boxhead81' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead8li1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li1} name='boxhead8li1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li11} name='boxhead8li11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead8li2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li2} name='boxhead8li2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li21} name='boxhead8li21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead8li3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li3} name='boxhead8li3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li31} name='boxhead8li31' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">boxhead8li4</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li4} name='boxhead8li4' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.boxhead8li41} name='boxhead8li41' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">downhead1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead1} name='downhead1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead11} name='downhead11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">cardh1</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh1} name='cardh1' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh11} name='cardh11' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">cardh1con</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh1con} name='cardh1con' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh1con1} name='cardh1con1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">cardh2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh2} name='cardh2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh21} name='cardh21' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">cardh2con</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh2con} name='cardh2con' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh2con1} name='cardh2con1' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">cardh3</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh3} name='cardh3' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh31} name='cardh31' />
                </div>
              </div>
            </div>



            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">cardh3con</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh3con} name='cardh3con' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.cardh3con1} name='cardh3con1' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">downhead2</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead2} name='downhead2' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.downhead21} name='downhead21' />
                </div>
              </div>
            </div>


            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label">end</label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.end} name='end' />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-2 col-form-label"></label>
                <div className="col-sm-10">
                  <input type="text" className="form-control" onChange={inputevent} value={fullname.end1} name='end1' />
                </div>
              </div>
            </div>







            <button type="submit" class="btn btn-primary" onClick={buttonclickevent}>Save Data</button>
          </div>
        </section>

      </main>
    </>

  )

}