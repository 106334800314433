import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import API from './Api'

export default function ChangePassword() {
  const router = useNavigate();
  const [fullname, setfullname] = useState({
    oldpassword: "",
    npassword: "",
    cpassword: ""
  });

  const inputevent = (event) => {
    const { value, name } = event.target;

    setfullname((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const buttonclickevent = (event) => {
    event.preventDefault();
    axios.post(`${API.BASE_URL}/admin/changePassword`, {
      ...fullname,
      accessToken: localStorage.getItem("token")
    }).then((res) => {
      if ((res.data.status) && (res.data.code == 200)) {
        window.alert(res.data.message);
      } else if (!res.data.status) {
        window.alert(res.data.message);
      }
      // }else{
      //   localStorage.clear();
      //   window.alert("Session expired, please login again");
      //   router('/login');
      // }
    }).catch((err) => {
      window.alert(err.message);
    })
  };

  return (
    <>
      <main id="main" className="main">
        <section className="section avatarsec">
          <div className="row">
            <div className="col-lg-12">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-12 col-form-label">
                  Old Password
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={inputevent}
                    name="oldpassword"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-12 col-form-label">
                  New Password
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={inputevent}
                    name="npassword"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="row mb-3">
                <label for="inputText" className="col-sm-12 col-form-label">
                  Confirm Password
                </label>
                <div className="col-sm-10">
                  <input
                    type="text"
                    className="form-control"
                    onChange={inputevent}
                    name="cpassword"
                  />
                </div>
              </div>
            </div>

            <button
              type="submit"
              class="btn btn-primary submmit"
              onClick={buttonclickevent}
            >
              Save Data
            </button>
          </div>
        </section>
      </main>
    </>
  );
}
