import React from 'react'
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import EditorToolbar, { formats, undoChange, redoChange } from './Tools'

export const TextEditor = ({ num, ...props }) => {
  const modules = {
    toolbar: {
      container: `#toolbar${num}`,
      handlers: {
        undo: undoChange,
        redo: redoChange
      }
    },
    history: {
      delay: 500,
      maxStack: 100,
      userOnly: true
    }
  };
  return (
    <>
      <EditorToolbar num={num} />
      <ReactQuill theme="snow" value={props?.content} modules={modules} formats={formats} onChange={(value) => {
        props.onChange(value)
      }} />
    </>
  )
}