import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

function ProtectedRoute( props ) {
  const token = localStorage.getItem("token");
  let isAuthenticated = (token != null ? token.length >= 148 : false);
  const Component = props.component;
  const [isTrue,setTrue] = useState(true);
  const router = useNavigate();
  useEffect(()=>{
    if(isTrue){
      if(!isAuthenticated && props.name === '/Recover'){
        router('/forgot-password')
      }else if(!isAuthenticated && props.name === '/Login'){
        router('/login')
      }else if(props.name === '/Login' || props.name === '/Recover'){
        router('/')
      }else if(!isAuthenticated){
        router('/login')
      }
      setTrue(false)
    }
  });

  return (
    <Component />
  );
}

export default ProtectedRoute;
